import Axios from 'axios';
import axiosInstance from '../axios/axiosInstance';
const baseURL = process.env.REACT_APP_API_BASE_URL;

//home
export const getPhrases = () => Axios.get(`${baseURL}phrases?source=web2`);

export const stillHaveQuestions = (body) =>
	axiosInstance.post(`common/generalinquiry`, body);

export const placeBid = (itemId, bidAmount, autoBid, auctionType) =>
	axiosInstance.get(
		`auctions/placebid?itemid=${itemId}&bidamount=${bidAmount}&auctiontype=${auctionType}&isproxy=${autoBid}`
	);

export const confirmBid = (bidId) =>
	axiosInstance.get(`auctions/confirmbid?confirmbidid=${bidId}`);

export const getPaymentDetails = () =>
	axiosInstance.get(`common/getdeposittypes`);

export const getFaqs = (auctionType, id) =>
	axiosInstance.get(
		`common/auctionotherdetails?auctionType=${auctionType}&itemId=${id}`
	);

export const getMaybeInterted = (id, auctionType) =>
	axiosInstance.get(
		`common/similaritems?auctionType=${auctionType}&itemId=${id}`
	);

export const addToFavorite = (to, id) => {
	return axiosInstance.get(`${to}/watch/add?itemid=${id}`);
};

export const removeFromFavorite = (to, id) =>
	axiosInstance.get(`${to}/watch/remove?itemid=${id}`);
export const getTermsAndConditions = () =>
	axiosInstance.get(`common/termsandconditions`);

// OTP FLOW

export const sendToEmailForgetPassword = (email) =>
	axiosInstance.get(
		`account/forgotpasswordbyemail?email=${email}&lang=${
			localStorage.getItem('lang') || 'en'
		}`
	);

export const sendToMobileForgetPassword = (data) =>
	axiosInstance.post(`account/forgotpasswordsearchbymobile`, data);
export const getFaqHome = () => axiosInstance.get(`common/faqs/10`);
