import CarsIcon from '../assets/icons/checkout/cars-auctioned.png';
import GeneralIcon from '../assets/icons/checkout/general.png';
import PlatesIcons from '../assets/icons/checkout/plates.png';
import PlanIcon from '../assets/icons/checkout/vip-plan.png';
import HomeIcon from '../assets/icons/checkout/home-run-2-copy.png';
export const allPlates = (orders) => {
	const plates = orders?.Plates;
	return (
		plates?.FujPlates.concat(plates?.RAKPlates)
			.concat(plates?.ShjPlates)
			.concat(plates?.UQPlates)
			.concat(plates?.AjmPlates) || []
	);
};
export const PlateAmountAndIds = (orders) => {
	const plates = orders?.Plates;
	const allPlatesValues = plates?.FujPlates.concat(plates?.RAKPlates)
		.concat(plates?.ShjPlates)
		.concat(plates?.UQPlates)
		.concat(plates?.AjmPlates);
	const data = { ids: [], amount: 0, arrayOfAmounts: [] };
	allPlatesValues.forEach((v, i) => {
		data.ids.push(`${v?.AuctionInfo?.itemAuctionType}-${v?.plateId}`);
		data.arrayOfAmounts.push(v?.AuctionInfo?.currentPrice);
	});
	data.amount = data.arrayOfAmounts.length
		? data.arrayOfAmounts.reduce((a, b) => a + b)
		: 0;
	data.ids = data.ids.toString();
	return data;
};
export const PlanAmountAndIds = (orders) => {
	const Mobiles = orders?.Mobiles?.Etisalat;
	const data = { ids: [], amount: 0, arrayOfAmounts: [] };
	Mobiles.forEach((v, i) => {
		data.ids.push(`${v?.AuctionInfo?.itemAuctionType}-${v?.mobileId}`);
		data.arrayOfAmounts.push(v?.AuctionInfo?.currentPrice);
	});
	data.amount = data.arrayOfAmounts.length
		? data.arrayOfAmounts.reduce((a, b) => a + b)
		: 0;
	data.ids = data.ids.toString();
	return data;
};

export const getDirectSaleCount = (cartItems) => {
	let count = 0;
	if (cartItems) {
		count =
			(cartItems?.Plates?.FujPlates?.length || 0) +
				(cartItems?.Plates?.RAKPlates?.length || 0) +
				(cartItems?.Plates?.ShjPlates?.length || 0) +
				(cartItems?.Plates?.UQPlates?.length || 0) +
				(cartItems?.Plates?.AjmPlates?.length || 0) +
				(cartItems?.Mobiles?.Etisalat?.length || 0) || 0;
	}
	return count;
};
export const getDirectSaleTabsValid = (cartItems) => {
	let count = 0;
	if (cartItems) {
		count =
			(cartItems?.Plates?.FujPlates?.length || 0) +
				(cartItems?.Plates?.RAKPlates?.length || 0) +
				(cartItems?.Plates?.ShjPlates?.length || 0) +
				(cartItems?.Plates?.UQPlates?.length || 0) +
				(cartItems?.Plates?.AjmPlates?.length || 0) || 0;
	}
	if (cartItems?.Mobiles?.Etisalat?.length && count) {
		return true;
	}
	return false;
};

export const getOrderCount = (orders) => {
	console.log('orders', orders);
	if (orders && orders.length) {
		return orders.map((v, i) => v.Online.count).reduce((a, b) => a + b) || 0;
	}
	return 0;
};
export const getImageForAuctionType = (type) => {
	const plate =
		type == 1 ||
		type == 7 ||
		type == 12 ||
		type == 15 ||
		type == 21 ||
		type == 25;
	if (plate) {
		return PlatesIcons;
	} else if (type == 4) {
		return CarsIcon;
	} else if (type == 11 || type == 12 || type == 13) {
		return PlanIcon;
	} else if (type == 9) {
		return HomeIcon;
	} else {
		return GeneralIcon;
	}
};
