import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

const ProtectedRoute = ({ component: Component, ...rest }) => {
	const [auth] = useContext(AuthContext);

	return (
		<Route
			{...rest}
			render={(props) => {
				const component = <Component {...props} />;
				if (!auth?.isAuth) {
					return <Redirect to="/" />;
				}
				return component;
			}}
		/>
	);
};

export default ProtectedRoute;
