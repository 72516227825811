import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import Layout from './containers/Layout/Layout';
import { AuthProvider } from './context/AuthContext';
import { BrowserRouter } from 'react-router-dom';
import tagManagerInitialize from './tag-manager/initialize';
import { SnackbarProvider } from 'notistack';
import { LocalizationProvider } from './context/LocalizationContext';
import { Provider } from 'react-redux';
import { store } from './store/index';
import { PhrasesProvider } from './context/PhrasesContext';
import ReactPixel from 'react-facebook-pixel';

// FacebookPixel Config
const advancedMatching = {};
const options = {
	autoConfig: true,
	debug: false,
};
ReactPixel.init(
	process.env.REACT_APP_FACEBOOK_PIXEL_ID,
	advancedMatching,
	options
);
ReactPixel.pageView();

const notistackRef = React.createRef();

ReactDOM.render(
	<React.StrictMode>
		<AuthProvider>
			<BrowserRouter>
				<PhrasesProvider>
					<LocalizationProvider>
						<SnackbarProvider
							maxSnack={3}
							anchorOrigin={{
								vertical: 'top',
								horizontal:
									localStorage.getItem('lang') === 'en' ? 'right' : 'left',
							}}
							ref={notistackRef}
							autoHideDuration={5000}
						>
							<Provider store={store}>
								<Layout />
							</Provider>
						</SnackbarProvider>
					</LocalizationProvider>
				</PhrasesProvider>
			</BrowserRouter>
		</AuthProvider>
	</React.StrictMode>,
	document.getElementById('root')
);

serviceWorker.unregister();
// tagManagerInitialize();
