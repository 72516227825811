import React, { useContext, useEffect, useState } from 'react';
import {
	Container,
	useMediaQuery,
	InputBase,
	Grid,
	Button,
	Typography,
} from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import Trustwave from '../../../assets/images/footer/trustwave.png';
import Norton from '../../../assets/images/footer/norton.png';
import SecurePayment from '../../../assets/images/footer/secure-payment.png';
import Mazad from '../../../assets/images/footer/mazad.png';
import { useStyles } from './styles';

import FB_ICON from '../../../assets/images/home/group-13.png';
import TWITTER_ICON from '../../../assets/images/home/group-11.png';
import INSTAGRAM_ICON from '../../../assets/images/home/group-12.png';
import YOUTUBE_ICON from '../../../assets/images/home/group-14.png';
import LINKEDIN_ICON from '../../../assets/images/home/group-15.png';
import GOOGLE_PLAY_ICON from '../../../assets/images/home/google_play.png';
import APP_STORE_ICON from '../../../assets//images//home/App_store.png';
import { useHistory, useLocation } from 'react-router';
import { getAuctionCategories } from '../../../api/home';
import { PhrasesContext } from '../../../context/PhrasesContext';

const Footer = () => {
	const isSmallScreen = useMediaQuery('(max-width:1016px)');
	const isExtraSmallScreen = useMediaQuery('(max-width:600px)');
	const classes = useStyles({ isSmallScreen, isExtraSmallScreen })();
	const history = useHistory();
	const date = new Date();
	const year = date.getFullYear();
	const [categories, setCategries] = useState([]);
	const locale = useIntl().locale;
	const [phrases] = useContext(PhrasesContext);

	useEffect(() => {
		getAuctionCategories().then((res) => {
			if (res?.data?.Auctions?.length > 0) {
				const cats = res.data.Auctions.slice();
				const plateCategories = cats.filter((v) => v.auctionTemplate == 1);
				setCategries(plateCategories);
			}
		});
	}, []);

	const openPlateHandler = (v) => {
		let type = '';
		if (v.BuyNow.count) {
			type = 'direct';
		} else if (v.Online.count) {
			type = 'online';
		} else {
			type = 'physical';
		}
		history.push(`/auctions/1/${v.APITag}/${type}/1`);
	};

	return (
		<Container
			component="footer"
			maxWidth={false}
			className={classes.container}
		>
			<div className={classes.section}>
				<div className={classes.newsletterPart}>
					<InputBase
						className={`${classes.margin}`}
						placeholder="Enter your email address"
						inputProps={{ 'aria-label': 'naked', style: { color: '#b6bfc6' } }}
					/>
					<Button variant="outlined" onClick={() => history.push('/signup')}>
						{phrases['btnSubscribe']?.[locale]}
					</Button>
				</div>
				<div className={classes.logos}>
					<img
						alt="norton"
						src={Norton}
						width={isExtraSmallScreen ? '79px' : '100px'}
						height={isExtraSmallScreen ? '30px' : '38px'}
					/>
					<img
						alt="trustwave"
						src={Trustwave}
						width={isExtraSmallScreen ? '119px' : '139px'}
						height={isExtraSmallScreen ? '16px' : '19px'}
					/>
					<img
						alt="securePayment"
						src={SecurePayment}
						width={isExtraSmallScreen ? '49px' : '89px'}
						height={isExtraSmallScreen ? '30px' : '55px'}
					/>
				</div>
			</div>
			<div className={`${classes.section} ${classes.moreInfo}`}>
				<div style={{ width: '95%' }}>
					{/* {phrases['Auctions']?.[locale]} */}
					<Typography
						variant="h3"
						component="h3"
						className={classes.sectionTitle}
					>
						{/* {phrases['Auctions']?.[locale]} */}
					</Typography>
					<Grid container spacing={10} className={classes.auctionsList}>
						<Grid item sm={4} xs={12} md={2} className={classes.gridItemCustom}>
							<Typography
								variant="h3"
								component="h3"
								className={classes.sectionTitle}
							>
								{phrases['btnAuctions']?.[locale]}
							</Typography>
							{categories.map((v) => {
								return (
									<p onClick={() => openPlateHandler(categories[0])}>
										{phrases && phrases[v.titlephraseKey] && (
											<FormattedMessage
												id={phrases[v.titlephraseKey]?.[locale]}
											/>
										)}
									</p>
								);
							})}
						</Grid>
						<Grid item sm={4} xs={12} md={2} className={classes.gridItemCustom}>
							<div className={classes.listSpacing}>
								<p onClick={() => history.push(`/auctions/4`)}>
									{phrases['lblVehicles']?.[locale]}
								</p>
								<p onClick={() => history.push(`/auctions/4`)}>
									{phrases['lblMachinery']?.[locale]}
								</p>
								<p onClick={() => history.push(`/auctions/9`)}>
									{phrases['lblDubaiProperty']?.[locale]}
								</p>
								<p onClick={() => history.push(`/auctions/9`)}>
									{phrases['lblHomeAbuDhabiProperty']?.[locale]}
								</p>
								<p onClick={() => history.push(`/auctions/11`)}>
									{phrases['lblEtisalatVipPlans']?.[locale]}
								</p>
							</div>
						</Grid>
						<Grid item sm={4} xs={12} md={2} className={classes.gridItemCustom}>
							<div className={classes.listSpacing}>
								<p onClick={() => history.push(`/auctions/5`)}>
									{phrases['lblSurplus']?.[locale]}
								</p>
								<p onClick={() => history.push(`/auctions/5`)}>
									{phrases['lblCharity']?.[locale]}
								</p>
							</div>
						</Grid>
						<Grid item sm={6} xs={12} md={2} className={classes.gridItemCustom}>
							<div>
								<Typography
									variant="h3"
									component="h3"
									className={classes.sectionTitle}
								>
									{phrases['lblAboutTheCompany']?.[locale]}
								</Typography>
								<div>
									<p onClick={() => history.push(`/about`)}>
										{phrases['lblAboutEmiratesAuction']?.[locale]}
									</p>
									<p onClick={() => history.push(`/`)}>
										{phrases['lblHowWeStarted']?.[locale]}
									</p>
									<p onClick={() => history.push('/faqs')}>
										{phrases['btnFAQs']?.[locale]}
									</p>
									<p onClick={() => history.push('/contact')}>
										{phrases['btnContactUs']?.[locale]}
									</p>
								</div>
							</div>
						</Grid>

						<Grid
							item
							sm={6}
							xs={12}
							md={4}
							className={`${classes.gridItemCustom} ${classes.gridItemCustom}`}
						>
							<div>
								<div className={classes.horizontalLogo}>
									<img
										alt="mazad"
										src={Mazad}
										width={isSmallScreen ? '160px' : '220px'}
									/>
								</div>

								<div className={classes.socialIconsWrapper}>
									<div>
										<a
											target="_blank"
											href="https://www.facebook.com/emiratesauction"
										>
											<img
												style={{
													marginInlineEnd: '12px',
													cursor: 'pointer',
													width: isSmallScreen && '46px',
													height: isSmallScreen && '46px',
												}}
												src={FB_ICON}
												alt="face"
											/>
										</a>
									</div>
									<div>
										<a
											target="_blank"
											href="https://twitter.com/emiratesauction"
										>
											<img
												style={{
													marginInlineEnd: '12px',
													cursor: 'pointer',
													width: isSmallScreen && '46px',
													height: isSmallScreen && '46px',
												}}
												src={TWITTER_ICON}
												alt="twitter"
											/>
										</a>
									</div>
									<div>
										<a
											target="_blank"
											href="https://www.instagram.com/emiratesauction/"
										>
											<img
												style={{
													marginInlineEnd: '12px',
													cursor: 'pointer',
													width: isSmallScreen && '46px',
													height: isSmallScreen && '46px',
												}}
												src={INSTAGRAM_ICON}
												alt="instgram"
											/>
										</a>
									</div>
									<div>
										<a
											target="_blank"
											href="https://www.youtube.com/user/emiratesauction"
										>
											<img
												style={{
													marginInlineEnd: '12px',
													cursor: 'pointer',
													width: isSmallScreen && '46px',
													height: isSmallScreen && '46px',
												}}
												src={YOUTUBE_ICON}
												alt="youtube"
											/>
										</a>
									</div>
									<div>
										<a
											target="_blank"
											href="https://www.linkedin.com/company/emirates-auction"
										>
											<img
												style={{
													marginInlineEnd: '12px',
													cursor: 'pointer',
													width: isSmallScreen && '46px',
													height: isSmallScreen && '46px',
												}}
												src={LINKEDIN_ICON}
												alt="linkedin"
											/>
										</a>
									</div>
								</div>
								<div className={classes.socialIconsDownloads}>
									<div>
										<a
											target="_blank"
											href="https://play.google.com/store?utm_source=emea_Med&utm_medium=hasem&utm_content=Oct1515&utm_campaign=Evergreen&pcampaignid=MKT-EDR-emea-eg-all-Med-hasem-py-Evergreen-Dec0215-1-BKWS%7cONSEM_kwid_43700009237562142&gclid=Cj0KCQiA9P__BRC0ARIsAEZ6iri38i3_Hc7nmnNu_1oW-EnHwStY2gISaYaJhvaLsXDQA0OgloK6SccaAt8LEALw_wcB&gclsrc=aw.ds"
										>
											<img
												style={{
													marginInlineEnd: '12px',
													// marginRight: '69px',													cursor: 'pointer',
													width: isSmallScreen && '123px',
													height: isSmallScreen && '37px',
												}}
												src={GOOGLE_PLAY_ICON}
												alt="google_play"
											/>
										</a>
									</div>
									<div>
										<a
											target="_blank"
											href="https://www.apple.com/eg/app-store/"
										>
											<img
												style={{
													cursor: 'pointer',
													width: isSmallScreen && '123px',
													height: isSmallScreen && '37px',
												}}
												src={APP_STORE_ICON}
												alt="app_store"
											/>
										</a>
									</div>
								</div>
							</div>
						</Grid>
					</Grid>
				</div>
			</div>
			<div className={classes.section}>
				<Typography variant="h6" component="p" className={classes.credits}>
					{year} {phrases['lbl2021Copyright']?.[locale]}
				</Typography>
			</div>
		</Container>
	);
};

export default Footer;
