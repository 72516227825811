export const ACTION_TYPES = {
	UPDATE_VEHICLE_FILTERS: 'UPDATE_VEHICLE_FILTERS',
	CLEAR_VEHICLE_FILTERS: 'CLEAR_VEHICLE_FILTERS',
	UPDATE_VEHICLE_OPTIONS: 'UPDATE_VEHICLE_OPTIONS',
	UPDATE_VIEW_TYPE: 'UPDATE_VIEW_TYPE',

	AUCTION: {
		PLANS: {
			UPDATE_PLANS_FILTER: 'UPDATE_PLANS_FILTER',
			CLEAR_PLANS_FILTERS: 'CLEAR_PLANS_FILTERS',
			UPDATE_VIEW_TYPE: 'UPDATE_VIEW_TYPE',
		},
		PLATES: {
			UPDATE_PLATES_FILTER: 'UPDATE_PLATES_FILTER',
			CLEAR_PLATES_FILTERS: 'CLEAR_PLATES_FILTERS',
			UPDATE_VIEW_TYPE: 'UPDATE_VIEW_TYPE',
			UPDATE_PLATES_FILTERS: 'UPDATE_PLATES_FILTERS',
			CLEAR_ALL_PLATES_FILTERS: 'CLEAR_ALL_PLATES_FILTERS',
			VIEW_CAR: 'VIEW_CAR',
		},
		OTHERS: {
			UPDATE_VIEW_TYPE: 'UPDATE_VIEW_TYPE',
			UPDATE_OTHERS_FILTERS: 'UPDATE_OTHERS_FILTERS',
		},
	},
	UPDATE_PROPERTIES_FILTERS: 'UPDATE_PROPERTIES_FILTERS',
	SEARCH_PROPERTY: 'SEARCH_PROPERTY',

	CLEAR_PROPERTIES_FILTERS: 'CLEAR_PROPERTIES_FILTERS',
	CLEAR_PROPERTIES_LIST: 'CLEAR_PROPERTIES_LIST',

	REFRESH_PROPERTIES_FILTERS: 'REFRESH_PROPERTIES_FILTERS',
	UPDATE_VIEW_TYPE_PROPERTIES: 'UPDATE_VIEW_TYPE_PROPERTIES',
	SCROLL_TO_VIEW_PROPERTY: 'SCROLL_TO_VIEW_PROPERTY',
	ADD_REMOVE_FAVORITE_PROPERTY: 'ADD_REMOVE_FAVORITE_PROPERTY',

	UPDATE_VEHICLES_FILTERS: 'UPDATE_VEHICLES_FILTERS',
	CLEAR_VEHICLES_FILTERS: 'CLEAR_VEHICLES_FILTERS',
};
