import { ACTION_TYPES } from '../actionTypes';
import * as _ from 'lodash';

const initialState = {
	page: 1,
	sortoption: 'endtime',
	sortdirection: 'desc',
	fparams: '',
	foption: '',
	itemperpage: 10,
	type: '',
	location: '',
	search: '',
	viewType: 'List',
	price: '',
};
const reducer = (state = initialState, action) => {
	switch (action.type) {
		case ACTION_TYPES.UPDATE_PROPERTIES_FILTERS:
			return { ...state, [action.payload.key]: action.payload.value };

		case ACTION_TYPES.CLEAR_PROPERTIES_FILTERS:
			return {
				...state,
				fparams: '',
				foption: '',
				type: '',
				location: '',
				search: '',
				price: '',
			};

		case ACTION_TYPES.UPDATE_VIEW_TYPE_PROPERTIES: {
			return { ...state, viewType: action.viewType };
		}

		default:
			return state;
	}
};

export default reducer;
