import React, { useContext } from 'react';
import makeStyles from './styles';
import SuccessImage from '../../../assets/images/my-deposits/success.png';
import FailureImage from '../../../assets/images/my-deposits/failed.png';
import { PhrasesContext } from '../../../context/PhrasesContext';
import { useIntl } from 'react-intl';

import { Avatar, Button, useMediaQuery } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useTheme } from '@material-ui/styles';
const ResponseInquery = ({ changeModal, response }) => {
	const classes = makeStyles();
	const theme = useTheme();

	const locale = useIntl().locale;
	const [phrases] = useContext(PhrasesContext);

	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const isSmallExtraScreen = useMediaQuery(theme.breakpoints.down('xs'));

	const renderSuccessModal = () => (
		<div className={classes.modalContainer}>
			<img className={classes.imgResponse} src={SuccessImage} />
			<div className={classes.subHeaderMessage}>
				<label style={{ fontSize: '20px' }}>
					{phrases['lblSentSuccessfully']?.[locale]}
				</label>
				<p style={{ color: '#77797e', fontWeight: 'normal', width: '250px' }}>
					{phrases['lblMessageSuccessTxt']?.[locale]}
				</p>
			</div>

			<div>
				<Button
					variant="contained"
					className={classes.btnPrimary}
					onClick={() => changeModal()}
				>
					{phrases['btnDoneDeliveryLocation']?.[locale]}
				</Button>
			</div>
		</div>
	);

	const renderFailureModal = () => (
		<div className={classes.modalContainer}>
			<img className={classes.imgResponse} src={FailureImage} />
			<div className={classes.text}>
				<p className={classes.subHeaderMessage} style={{ marginTop: '30px' }}>
					Your message wasn't delivered
				</p>
				<p className={classes.subHeaderMessage}>due to some reason.</p>
			</div>

			<div>
				<Button
					variant="contained"
					className={classes.btnPrimary}
					onClick={() => changeModal()}
				>
					Try Again
				</Button>
			</div>
		</div>
	);

	return (
		<div className={classes.modal}>
			<div
				style={{
					textAlign: 'end',
				}}
			>
				<div className={classes.iconContainer}>
					<CloseIcon
						style={{
							textAlign: 'end',
							color: '#ff422f',
							position: 'relative',
							top: '30px',
							left: locale == 'en' ? 'auto' : '30px',
							right: locale == 'en' ? '30' : 'auto',
							cursor: 'pointer',
							width: '22px',
						}}
						onClick={() => changeModal()}
					/>
				</div>

				{response === 'SUCCESS_RESPONSE'
					? renderSuccessModal()
					: renderFailureModal()}
			</div>
		</div>
	);
};

export default ResponseInquery;
