import { ACTION_TYPES } from '../actionTypes';
import produce from 'immer';
import * as _ from 'lodash';
import { RENDER_TYPE } from '../../utils/helper';

const initialState = {
	filterSelections: {
		plans: {
			header: 'plates.filter.type.title',
			type: RENDER_TYPE.RADIO_BUTTONS_WITH_ALL,
			children: {
				threeDigits: {
					label: 'plates.filter.type.threeDigits',
					value: false,
				},
				fourDigits: {
					label: 'plates.filter.type.fourDigits',
					value: false,
				},
				fiveDigits: {
					label: 'plates.filter.type.fiveDigits',
					value: false,
				},
			},
		},
		// price: {
		// 	header: 'plates.filter.price.title',
		// 	type: RENDER_TYPE.RANGE,
		// 	value: { from: '0', to: '1000000' },
		// },
	},
	viewType: 'Grid',
};

const updateFilters = (state, { path, value }) => {
	if (path === 'filterSelections.plans.children') {
		return produce(state, (draftState) => {
			_.set(draftState, path, {
				threeDigits: {
					label: 'plates.filter.type.threeDigits',
					value: false,
				},
				fourDigits: {
					label: 'plates.filter.type.fourDigits',
					value: false,
				},
				fiveDigits: {
					label: 'plates.filter.type.fiveDigits',
					value: false,
				},
			});
		});
	} else {
		const tmpState = produce(state, (draftState) => {
			_.set(draftState, `${path}.value`, value);
		});
		return tmpState;
	}
};

const clearFilters = (state) => {
	return {
		filterSelections: {
			plans: {
				header: 'plates.filter.type.title',
				type: RENDER_TYPE.RADIO_BUTTONS_WITH_ALL,
				children: {
					threeDigits: {
						label: 'plates.filter.type.threeDigits',
						value: false,
					},
					fourDigits: {
						label: 'plates.filter.type.fourDigits',
						value: false,
					},
					fiveDigits: {
						label: 'plates.filter.type.fiveDigits',
						value: false,
					},
				},
			},
			price: {
				header: 'plates.filter.price.title',
				type: RENDER_TYPE.RANGE,
				value: { from: '0', to: '100' },
			},
		},
	};
};

const updateViewType = (state, { viewType }) => {
	return produce(state, (draftState) => {
		draftState.viewType = viewType;
	});
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case ACTION_TYPES.AUCTION.PLATES.UPDATE_PLATES_FILTER:
			return updateFilters(state, action);
		case ACTION_TYPES.AUCTION.PLATES.CLEAR_PLATES_FILTERS:
			return clearFilters(state, action);
		case ACTION_TYPES.AUCTION.PLATES.UPDATE_VIEW_TYPE:
			return updateViewType(state, action);
		default:
			return state;
	}
};

export default reducer;
