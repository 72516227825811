import React from 'react';
import Header from '../components/Layout/Header';
import Footer from '../components/Layout/Footer';
import { ShoppingCartProvider } from '../context/ShoppingCartContext';
import { WatchlistProvider } from '../context/WatchlistContext';
import Scroll from './Scroll';

const AppLayout = ({ children }) => (
	<ShoppingCartProvider>
		<WatchlistProvider>
			<Header />
			{children}

			<Footer />
		</WatchlistProvider>
	</ShoppingCartProvider>
);

export default AppLayout;
