import React from 'react';

export default function Map({ lat, long, height }) {
	const apiKey = process.env.REACT_APP_GOOGLE_EMBEDED_API_KEY;

	return (
		<>
			{lat && (
				<iframe
					title="Branch Location"
					width="100%"
					height={height || '300'}
					frameBorder="0"
					style={{ border: 0 }}
					allowFullScreen
					src={`https://www.google.com/maps/embed/v1/place?q=${lat}%2C%20${long}&key=${apiKey}`}
				/>
			)}
		</>
	);
}
