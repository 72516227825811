import React, { useState } from 'react';

import useStyles from './styles';
import { Grid, TextField, Container, Button } from '@material-ui/core';
import {
	contactUsFormValid,
	checkContactUsValid,
} from '../../../utils/contactUsFormValidation';
import { stillHaveQuestions } from '../../../api/common';

const ContactUsInputs = ({ inqueryStatus }) => {
	const classes = useStyles();
	const [sendingData, setSendingData] = useState({
		name: '',
		email: '',
		message: '',
		mobile: '',
	});
	const [error, setError] = useState({
		name: '',
		email: '',
		message: '',
		mobile: '',
	});

	const onChangeValues = (state, value) => {
		var trimedVal = value;
		while (trimedVal.charAt(0) === ' ') trimedVal = trimedVal.slice(1);
		const errorMessage = contactUsFormValid(state, trimedVal);
		setSendingData({ ...sendingData, [state]: trimedVal });
		errorMessage
			? setError({ ...error, [state]: errorMessage })
			: setError({ ...error, [state]: '' });
	};
	const resetData = () =>
		setSendingData({ name: '', email: '', message: '', mobile: '' });
	const resetErrors = () =>
		setError({ name: '', email: '', message: '', mobile: '' });

	const handleSubmit = () => {
		const formValid = checkContactUsValid(sendingData);
		if (!formValid.name && !formValid.message && !formValid.email) {
			const data = {
				message: sendingData.message.trim(),
				mobile: sendingData.mobile.trim(),
				email: sendingData.email.trim(),
				name: sendingData.name.trim(),
			};
			stillHaveQuestions(data)
				.then((Res) => {
					resetData();
					resetErrors();
					inqueryStatus('SUCCESS_RESPONSE');
				})
				.catch((err) => {
					inqueryStatus('FAILURE_RESPONSE');
				});
		} else {
			setError(formValid);
		}
	};

	return (
		<div>
			<Container>
				<Grid container className={classes.padding}>
					<Grid xs={12} lg={4} className={classes.inputContainer}>
						<TextField
							id="standard-basic"
							label="Your Name"
							value={sendingData.name}
							onBlur={(e) => onChangeValues('name', e.target.value)}
							onChange={(e) => onChangeValues('name', e.target.value)}
						/>
						{error.name && (
							<div className={classes.validation}>{error.name}</div>
						)}
					</Grid>

					<Grid xs={12} lg={4} className={classes.inputContainer}>
						<TextField
							id="standard-basic"
							label="Email Address"
							value={sendingData.email}
							onBlur={(e) => onChangeValues('email', e.target.value)}
							onChange={(e) => onChangeValues('email', e.target.value)}
						/>
						{error.email && (
							<div className={classes.validation}>{error.email}</div>
						)}
					</Grid>

					<Grid xs={12} lg={4} className={classes.inputContainer}>
						<TextField
							id="standard-basic"
							label="Mobile Number"
							value={sendingData.mobile}
							onBlur={(e) => onChangeValues('mobile', e.target.value)}
							onChange={(e) => onChangeValues('mobile', e.target.value)}
						/>
						{error.mobile && (
							<div className={classes.validation}>{error.mobile}</div>
						)}
					</Grid>

					<Grid xs={12}>
						<h2 className={classes.messageTitle}>Your Message</h2>
						<textarea
							value={sendingData.message}
							onBlur={(e) => onChangeValues('message', e.target.value)}
							onChange={(e) => onChangeValues('message', e.target.value)}
							className={classes.messageContent}
							rows={6}
							placeholder={'Send Message ... '}
							style={{
								border: error.message
									? '1px solid #ff422f'
									: 'solid 1px rgba(138, 138, 138, 0.5)',
							}}
						></textarea>
						{error.message && (
							<div className={classes.validationMessage}>{error.message}</div>
						)}
					</Grid>
				</Grid>
				<div>
					<Button
						color="primary"
						className={classes.submit}
						onClick={() => handleSubmit()}
					>
						Send
					</Button>
				</div>
			</Container>
		</div>
	);
};

export default ContactUsInputs;
