import axiosInstance from '../axios/axiosInstance';

export const getMessagesList = () => axiosInstance.get(`accounts/mytickets`);

export const getMessasgeDetails = (id) =>
	axiosInstance.get(`accounts/ticketdetail?id=${id}`);

export const addNewMessage = (data) =>
	axiosInstance.post(`accounts/newtickets`, data);

export const replyMessage = (data) =>
	axiosInstance.post(`accounts/replyticket`, data);
