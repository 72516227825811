import { ACTION_TYPES } from '../actionTypes';

const initialState = {
	page: 1,
	sortoption: 'endtime',
	sortdirection: 'desc',
	fparams: '',
	foption: '',
	itemperpage: 8,
	price: '',
	search: '',
	platenumber: '',
	openCarView: null,
	openCarViewObj: {},
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case ACTION_TYPES.AUCTION.PLATES.UPDATE_PLATES_FILTERS:
			return { ...state, [action.payload.key]: action.payload.value };

		case ACTION_TYPES.AUCTION.PLATES.VIEW_CAR:
			return {
				...state,
				openCarView: action.open,
				openCarViewObj: action.data,
			};

		case ACTION_TYPES.AUCTION.PLATES.CLEAR_ALL_PLATES_FILTERS:
			return {
				...state,
				sortoption: 'endtime',
				sortdirection: 'desc',
				fparams: '',
				foption: '',
				price: '',
				search: '',
				platenumber: '',
			};

		default:
			return state;
	}
};

export default reducer;
