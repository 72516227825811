import React, { useContext, useEffect, useState } from 'react';
import {
	Button,
	IconButton,
	Typography,
	useMediaQuery,
	Grid,
	Backdrop,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useStyle from './ShoppingCartStyle';
import PlatesIcon from '../../assets/images/header/plates.png';
import PlansIcon from '../../assets/images/header/vip-plan.png';
import NoAuctionsImage from '../../assets/images/my-deposits/confirmation.png';
import Divider from '@material-ui/core/Divider';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { Menu as MenuIcon } from '@material-ui/icons';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import Popover from '@material-ui/core/Popover';
import { ShoppingCartContext } from '../../context/ShoppingCartContext';
import TimeDown from '../TimeDown';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import { useHistory } from 'react-router';
import {
	namingForAuctions,
	backgroundImageForAuctions,
	PlateRoute,
} from '../../utils/autionType';
import {
	getDirectSaleCount,
	getDirectSaleTabsValid,
	getImageForAuctionType,
	getOrderCount,
} from '../../utils/checkout';
import { useIntl } from 'react-intl';
import { PhrasesContext } from '../../context/PhrasesContext';

const ShoppingCart = ({ cartItems, isCartOpened, setCartOpened }) => {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));
	const classes = useStyle({ isSmallScreen });
	const history = useHistory();
	const [, , auctionCheckouts] = useContext(ShoppingCartContext);
	const [checkouts, setCheckouts] = useState([]);
	const locale = useIntl().locale;
	const [phrases] = useContext(PhrasesContext);
	useEffect(() => {
		if (auctionCheckouts && auctionCheckouts.length) {
			let nonPlateCheckout = auctionCheckouts.filter(
				(v) => !PlateRoute(v.type)
			);
			let plateCheckout = JSON.parse(
				JSON.stringify(auctionCheckouts)
			).filter((v) => PlateRoute(v.type));
			let arrayOfCountsPlates = plateCheckout.map((v) => v?.Online?.count || 0);
			if (plateCheckout[0] && plateCheckout[0].Online) {
				plateCheckout[0].Online.count = arrayOfCountsPlates.reduce(
					(a, b) => a + b
				);
			}
			let checkout = nonPlateCheckout.length
				? nonPlateCheckout.concat(
						plateCheckout.length ? [plateCheckout[0]] : []
				  )
				: [plateCheckout[0]];
			setCheckouts(checkout);
		}
	}, [auctionCheckouts]);
	const tabs = [
		{
			name: 'Direct Sale Orders',
			items: `${getDirectSaleCount(cartItems)} Items`,
		},
		{
			name: 'Auction Orders',
			items: `${getOrderCount(auctionCheckouts)} Items`,
		},
	];
	const [currentTab, setCurrentTab] = useState('Direct Sale Orders');

	const renderSmallScreen = () => {
		return (
			<SwipeableDrawer
				open={Boolean(isCartOpened)}
				onClose={() => {
					setCartOpened(null);
				}}
				classes={{ paper: classes.drawerPaper, root: classes.drawerRoot }}
			>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}
				>
					{renderTabs()}
					<IconButton onClick={() => setCartOpened(null)} color="primary">
						<ChevronLeftIcon />
						<MenuIcon />
					</IconButton>
				</div>
				<Divider />

				<List>
					{currentTab === 'Direct Sale Orders'
						? renderCartItem()
						: renderItemAuction()}
				</List>
				<div className={classes.footer}>
					{/* {currentTab === 'Direct Sale Orders' &&
						(platesLenght() > 0 ||
							cartItems?.Mobiles?.Etisalat?.length > 0) && (
							<p className={classes.exText}>
								Direct sale items expire every 15 minutes
							</p>
						)} */}

					{currentTab === 'Direct Sale Orders' &&
						(platesLenght() > 0 ||
							cartItems?.Mobiles?.Etisalat?.length > 0) && (
							<Button
								className={classes.checkBtn}
								color="primary"
								variant="contained"
								onClick={() => handleCheckout()}
							>
								Checkout
							</Button>
						)}

					{currentTab === 'Auction Orders' && auctionCheckouts.length > 0 && (
						<Button
							className={classes.checkBtn}
							color="primary"
							variant="contained"
							onClick={() => handleCheckout()}
						>
							Checkout
						</Button>
					)}
				</div>
			</SwipeableDrawer>
		);
	};
	const handleCheckout = () => {
		setCartOpened(null);
		history.push('/auctions/order-summary');
	};

	const renderNormalScreen = () => {
		return (
			<Popover
				BackdropComponent={Backdrop}
				BackdropProps={{
					style: { background: 'rgba(0, 0, 0, 0.3)' },
					timeout: 500,
				}}
				open={Boolean(isCartOpened)}
				anchorEl={isCartOpened}
				onClose={() => setCartOpened(null)}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				className={classes.shoppingCartDialog}
			>
				{renderTabs()}
				<div className={classes.shoppingCartContent}>
					<List className={classes.shoppingList}>
						{currentTab === 'Direct Sale Orders'
							? renderCartItem()
							: renderItemAuction()}
					</List>
				</div>
				<div className={classes.footer}>
					{/* {currentTab === 'Direct Sale Orders' &&
						(platesLenght() > 0 ||
							cartItems?.Mobiles?.Etisalat?.length > 0) && (
							<p className={classes.exText}>
								Direct sale items expire every 15 minutes
							</p>
						)} */}

					{currentTab === 'Direct Sale Orders' &&
						(platesLenght() > 0 ||
							cartItems?.Mobiles?.Etisalat?.length > 0) && (
							<Button
								className={classes.checkBtn}
								color="primary"
								variant="contained"
								onClick={() => handleCheckout()}
							>
								Checkout
							</Button>
						)}

					{currentTab === 'Auction Orders' && auctionCheckouts.length > 0 && (
						<Button
							className={classes.checkBtn}
							color="primary"
							variant="contained"
							onClick={() => handleCheckout()}
						>
							Checkout
						</Button>
					)}
				</div>
			</Popover>
		);
	};

	const renderTabs = () => (
		<div style={{ width: '100%' }}>
			<Grid container>
				{tabs.map((v, i) => (
					<Grid xs={6}>
						{v.name === currentTab ? (
							<div
								className={classes.tab}
								onClick={() => setCurrentTab(v.name)}
							>
								<p className={classes.tabName}>{v.name}</p>
								<p className={classes.tabCount}>{v.items}</p>
							</div>
						) : (
							<div
								className={classes.tabNonActive}
								onClick={() => setCurrentTab(v.name)}
							>
								<p className={classes.tabNonActiveName}>{v.name}</p>
								<p className={classes.tabCount}>{v.items}</p>
							</div>
						)}
					</Grid>
				))}
			</Grid>
		</div>
	);

	const timeDown = () => {
		if (cartItems?.count) {
			return (
				<div>
					<WatchLaterIcon
						color="primary"
						style={{ margin: '-30px 22%', fontSize: '14px' }}
					/>
					<Typography className={classes.timeLeft} variant="h6">
						<TimeDown
							showLetters={true}
							timeStyle={classes.timeLeftColock}
							seconds={
								cartItems?.MyCartExpiry ? cartItems?.MyCartExpiry * 1000 : 0
							}
							upperCase={true}
							sperator=":"
						/>
					</Typography>
				</div>
			);
		}
	};

	const platesLenght = () =>
		cartItems?.Plates?.FujPlates?.length +
		cartItems?.Plates?.RAKPlates?.length +
		cartItems?.Plates?.ShjPlates?.length +
		cartItems?.Plates?.UQPlates?.length +
		cartItems?.Plates?.AjmPlates?.length;

	const renderCartItem = () => (
		<>
			{' '}
			{platesLenght() > 0 || cartItems?.Mobiles?.Etisalat?.length ? (
				<Grid container>
					{cartItems?.Mobiles?.Etisalat?.length > 0 && (
						<Grid
							xs={getDirectSaleTabsValid(cartItems) ? 6 : 12}
							style={{
								padding: getDirectSaleTabsValid(cartItems)
									? '0px 6px 0px 30px'
									: '0px 30px',
							}}
						>
							<div
								className={classes.itemCart}
								onClick={() => {
									if (cartItems?.Mobiles?.Etisalat?.length) {
										history.push(`/auctions/checkout/Plans`);
									}
									setCartOpened(null);
								}}
							>
								<div className={classes.carts}>
									<div className={classes.PlansImgDiv}>
										<img src={PlansIcon} alt="test" />
									</div>
									<p className={classes.type}>
										{phrases['lblMobileNumber']?.[locale]}
									</p>
									<p className={classes.itemType}>
										{cartItems?.Mobiles?.Etisalat?.length} items
									</p>
								</div>
								{cartItems?.Mobiles?.Etisalat?.length > 0 && timeDown()}
							</div>
						</Grid>
					)}

					{platesLenght() > 0 && (
						<Grid
							xs={getDirectSaleTabsValid(cartItems) ? 6 : 12}
							style={{
								padding: getDirectSaleTabsValid(cartItems)
									? '0px 30px 0px 6px'
									: '0px 30px',
							}}
						>
							<div
								className={classes.itemCart}
								onClick={() => {
									if (platesLenght()) {
										history.push(`/auctions/checkout/Plates`);
									}
									setCartOpened(null);
								}}
							>
								<div className={classes.carts}>
									<div className={classes.PlatesImgDiv}>
										<img src={PlatesIcon} alt="Test" />
									</div>
									<p className={classes.type}>Plates Number</p>
									<p className={classes.itemType}>{platesLenght()} items</p>
								</div>
								{platesLenght() > 0 && timeDown()}
							</div>
						</Grid>
					)}
				</Grid>
			) : (
				emptyAuction()
			)}
		</>
	);

	const emptyAuction = () => (
		<Grid container>
			<Grid xs={12}>
				<div style={{ textAlign: 'center' }}>
					<img src={NoAuctionsImage} className={classes.imgNoAct} alt="Test" />
					<h2 className={classes.hImgNoAct}>
						No{' '}
						{currentTab === 'Direct Sale Orders'
							? 'Direct Sale Orders'
							: 'Auction orders'}{' '}
						added yet
					</h2>
					<p className={classes.pImgNoAct}>
						You didn’t add any order items yet,
					</p>
					<p className={classes.pImgNoAct} style={{ marginBottom: '30px' }}>
						{currentTab === 'Direct Sale Orders'
							? 'please explore Direct Sale Orders'
							: 'please explore the auction section.'}
					</p>
				</div>
			</Grid>
		</Grid>
	);

	const renderItemAuction = (item) => (
		<>
			{auctionCheckouts.length ? (
				<Grid container style={{ padding: '0px 30px' }}>
					{checkouts.map((v, i) => (
						<Grid
							xs={checkouts.length == 1 ? 12 : 6}
							style={{
								padding: checkouts.length == 1 ? '0px 30px' : '0px 6px',
								marginBottom: '10px',
							}}
						>
							<div
								style={{ paddingBottom: '20px' }}
								className={classes.itemCart}
								onClick={() => {
									setCartOpened(null);
									history.push(`/auctions/sop/${v.type}`);
								}}
							>
								<div className={classes.carts}>
									<div
										className={classes.PlansImgDiv}
										style={{ background: backgroundImageForAuctions(v) }}
									>
										<img
											src={
												getImageForAuctionType(v.type) ||
												v?.Online?.CheckOutIconURL ||
												PlatesIcon
											}
											alt="test"
										/>
									</div>
									<p className={classes.type}>
										{namingForAuctions(v) ||
											phrases[v?.Online?.TitlePhraseKey]?.[locale]}
									</p>
									<p className={classes.itemType}>
										{' '}
										{v?.Online?.count} Pedding Sops
									</p>
								</div>
							</div>
						</Grid>
					))}
				</Grid>
			) : (
				emptyAuction()
			)}
		</>
	);

	return <>{isSmallScreen ? renderSmallScreen() : renderNormalScreen()}</>;
};

export default ShoppingCart;
