import VehicleIconDimmed from '../assets/icons/drawer/dimmed/vehicle.svg';
import PlateIconDimmed from '../assets/icons/drawer/dimmed/plate.svg';
import PropertyIconDimmed from '../assets/icons/drawer/dimmed/property.svg';
import GeneralIconDimmed from '../assets/icons/drawer/dimmed/more.svg';
import PlanIconDimmed from '../assets/icons/drawer/dimmed/plan.svg';
import SettingsIconDimmed from '../assets/icons/drawer/dimmed/settings.svg';

import vehicleIcon from '../assets/icons/home/category/vehicle.svg';
import HomeIcon from '../assets/icons/home/category/home.svg';
import GeneralIcon from '../assets/icons/home/category/general.svg';
import PlateIcon from '../assets/icons/home/category/plate.svg';
import PlanIcon from '../assets/icons/home/category/plan.svg';

import VehicleIconResponsive from '../assets/icons/home/category/car-compact-copy.png';
import PlateIconResponsive from '../assets/icons/home/category/group-3.png';
import PropertyIconResponsive from '../assets/icons/home/category/home-run-2-copy.png';
import GeneralIconResponsive from '../assets/icons/home/category/more.png';
import PlanIconResponsive from '../assets/icons/home/category/sim-2-copy.png';

export const PlateRoute = (plateCode) => {
	if (
		plateCode === 7 ||
		plateCode === 12 ||
		plateCode === 15 ||
		plateCode === 21 ||
		plateCode === 1 ||
		plateCode === 25
	) {
		return true;
	}
	return false;
};

export const auctionTypeName = (code) => {
	switch (Number(code)) {
		case 7:
			return 'uqonline';
		case 12:
			return 'fujonline';
		case 15:
			return 'rakonline';
		case 21:
			return 'shjonline';
		case 1:
			return 'adonline';
		case 25:
			return 'ajmonline';
		case 11:
			return 'etisalatonline';
		case 4:
			return 'cars';
		case 5:
			return 'surplus';

		default:
			return 'surplus';
	}
};
export const auctionTypeObject = (code) => {
	switch (Number(code)) {
		case 7:
			return 'Plates';
		case 12:
			return 'Plates';
		case 15:
			return 'Plates';
		case 21:
			return 'Plates';
		case 1:
			return 'Plates';
		case 25:
			return 'Plates';
		case 11:
			return 'Mobiles';
		default:
			break;
	}
};

export const auctions = [
	{
		id: 4,
		name: 'Vehicles ',
		image: VehicleIconDimmed,
		items: 0,
		backgroundImage:
			'linear-gradient(to bottom, #67b2ff, #67b2ff 54%, #67b2ff)',
	},
	{
		id: 1,
		name: 'Plates Number',
		image: PlateIconDimmed,
		items: 0,
		backgroundImage: 'linear-gradient(to bottom, #fe8695, #ff73b4 100%)',
	},
	{
		id: 25,
		name: 'Plates Number',
		image: PlateIconDimmed,
		items: 0,
		backgroundImage: 'linear-gradient(to bottom, #fe8695, #ff73b4 100%)',
	},
	{
		id: 15,
		name: 'Plates Number',
		image: PlateIconDimmed,
		items: 0,
		backgroundImage: 'linear-gradient(to bottom, #fe8695, #ff73b4 100%)',
	},
	{
		id: 7,
		name: 'Plates Number',
		image: PlateIconDimmed,
		items: 0,
		backgroundImage: 'linear-gradient(to bottom, #fe8695, #ff73b4 100%)',
	},
	{
		id: 12,
		name: 'Plates Number',
		image: PlateIconDimmed,
		items: 0,
		backgroundImage: 'linear-gradient(to bottom, #fe8695, #ff73b4 100%)',
	},
	{
		id: 21,
		name: 'Plates Number',
		image: PlateIconDimmed,
		items: 0,
		backgroundImage: 'linear-gradient(to bottom, #fe8695, #ff73b4 100%)',
	},
	{
		id: 9,
		name: 'Properties',
		image: PropertyIconDimmed,
		items: 0,
		backgroundImage:
			'linear-gradient(to bottom, #ff9d7b, #ff9d7b 52%, #ff9d7b 99%)',
	},
	{
		id: 11,
		name: 'Plans',
		image: PlanIconDimmed,
		items: 0,
		backgroundImage:
			'linear-gradient(to bottom, #8ae47a, #55df8a 54%, #27db97)',
	},
	{
		id: 5,
		name: 'Others',
		image: GeneralIconDimmed,
		items: 0,
		backgroundImage:
			'linear-gradient(to bottom, #a47ce9, #a47ce9 52%, #a47ce9 99%)',
	},
];

export const namingForAuctions = (data) =>
	auctions.filter((v) => v?.id === data?.type)[0]?.name;

export const backgroundImageForAuctions = (data) =>
	auctions.filter((v) => v?.id === data?.type)[0]?.backgroundImage ||
	'linear-gradient(to bottom, #a47ce9, #a47ce9 52%, #a47ce9 99%)';

export const PlateArray = [
	{
		image: 'https://cdn.emiratesauction.com/PLATES/shjnl2/300/3/7717.png',
		price: '121000',
		selectedOwner: {},
	},
	{
		image: 'https://cdn.emiratesauction.com/PLATES/shjnl2/300/3/7717.png',
		price: '121000',
		selectedOwner: {},
	},
	{
		image: 'https://cdn.emiratesauction.com/PLATES/shjnl2/300/3/7717.png',
		price: '121000',
		selectedOwner: {},
	},
	{
		image: 'https://cdn.emiratesauction.com/PLATES/shjnl2/300/3/7717.png',
		price: '121000',
		selectedOwner: {},
	},
	{
		image: 'https://cdn.emiratesauction.com/PLATES/shjnl2/300/3/7717.png',
		price: '121000',
		selectedOwner: {},
	},
	{
		image: 'https://cdn.emiratesauction.com/PLATES/shjnl2/300/3/7717.png',
		price: '121000',
		selectedOwner: {},
	},
	{
		image: 'https://cdn.emiratesauction.com/PLATES/shjnl2/300/3/7717.png',
		price: '121000',
		selectedOwner: {},
	},
	{
		image: 'https://cdn.emiratesauction.com/PLATES/shjnl2/300/3/7717.png',
		price: '121000',
		selectedOwner: {},
	},
	{
		image: 'https://cdn.emiratesauction.com/PLATES/shjnl2/300/3/7717.png',
		price: '121000',
		selectedOwner: {},
	},
	{
		image: 'https://cdn.emiratesauction.com/PLATES/shjnl2/300/3/7717.png',
		price: '121000',
		selectedOwner: {},
	},
	{
		image: 'https://cdn.emiratesauction.com/PLATES/shjnl2/300/3/7717.png',
		price: '121000',
		selectedOwner: {},
	},
];

export const requestChildOthers = (type) => {
	switch (Number(type)) {
		case 30:
			return 'showroom';
		case 19:
			return 'xonline';
		case 5:
			return 'surplusonline';

		default:
			break;
	}
};

export const requestPlateFilter = (type) => {
	switch (Number(type)) {
		case 7:
			return 'UQPlates';
		case 12:
			return 'FujPlates';
		case 15:
			return 'RAKPlates';
		case 21:
			return 'ShjPlates';
		case 1:
			return 'ADPlates';
		case 25:
			return 'AjmPlates';
		case 11:
			return 'Mobiles';
		default:
			break;
	}
};

export const plateFilterViewCars = (type) => {
	switch (Number(type)) {
		case 7:
			return 'uq';
		case 12:
			return 'fuj';
		case 15:
			return 'rak';
		case 21:
			return 'shj';
		case 1:
			return 'ad';
		case 25:
			return 'ajm';
		default:
			break;
	}
};
export const plateCities = [
	{
		typeId: 7,
		symbol: 'uq',
		name: 'UAQ Number Plate',
		arabicName: 'أم القيوين',
		englishName: 'Umm al-Quwain',
		logo: 'umm.png',
		count: 0,
	},
	{
		typeId: 12,
		symbol: 'fuj',
		name: 'Fujairah Number Plate',
		arabicName: 'الفجيرة',
		englishName: 'Fujairah',
		logo: 'fuj.png',
		count: 0,
	},
	{
		typeId: 15,
		symbol: 'rak',
		name: 'RAK Number Plate',
		arabicName: 'رأس الخيمة',
		englishName: 'Ras al-Khaimah',
		logo: 'rak.png',
		count: 0,
	},
	{
		typeId: 21,
		symbol: 'shj',
		name: 'Sharjah Number Plate',
		arabicName: 'الشارقة',
		englishName: 'Sharjah',
		logo: 'sharja.png',
		count: 0,
	},
	{
		typeId: 1,
		symbol: 'ad',
		name: 'Abu Dhabi Number Plate',
		arabicName: 'أبو ظبى',
		englishName: 'Abu Dhabi',
		logo: 'ad.png',
		count: 0,
	},
	{
		typeId: 25,
		symbol: 'ajm',
		name: 'Ajman Number Plate',
		arabicName: 'عجمان',
		englishName: 'Ajman',
		logo: 'ajmaan.png',
		count: 0,
	},
];

export const isPlates = (type) => {
	switch (Number(type)) {
		case 7:
			return true;
		case 12:
			return true;
		case 15:
			return true;
		case 21:
			return true;
		case 1:
			return true;
		case 25:
			return true;

		default:
			return false;
	}
};

export const auctionCategoryHandler = (array, responisve) => {
	const newData = array.slice();
	newData.forEach((v, i) => {
		if (v.auctionTemplate == 11) {
			if (v?.Online?.count) {
				v.auctionTemplate = 11;
			}
			if (v?.BuyNow?.count) {
				v.auctionTemplate = 12;
			}
			if (v?.Physical?.count) {
				v.auctionTemplate = 13;
			}
		}
	});
	let data = newData.filter(
		(v) => !isPlates(v.type) && v.type != 6 && v.auctionTemplate != 5
	);
	let plates = newData.filter((v) => isPlates(v.type));
	let others = newData.filter((v) => v.auctionTemplate == 5);
	data.push(data[1]);

	data = responisve ? iconsAuctionsResponsive(data) : iconsAuctions(data);
	plates = responisve ? iconsAuctionsResponsive(plates) : iconsAuctions(plates);
	return { data, plates, others };
};

const iconsAuctions = (array) => {
	const data = array.slice();
	const newData = data.map((v, i) => {
		switch (Number(v.type)) {
			case 1:
				v.icon = PlateIcon;
				break;
			case 9:
				v.icon = HomeIcon;
				break;
			case 4:
				v.icon = vehicleIcon;
				break;
			case 11:
				v.icon = PlanIcon;
				break;
			case 12:
				v.icon = PlanIcon;
				break;
			case 13:
				v.icon = PlanIcon;
				break;
			case 5:
				v.icon = GeneralIcon;
				break;

			default:
				v.icon = GeneralIcon;
				break;
		}
		return v;
	});
	return newData;
};

const iconsAuctionsResponsive = (array) => {
	const data = array.slice();
	const newData = data.map((v, i) => {
		switch (Number(v.type)) {
			case 1:
				v.icon = PlateIconResponsive;
				break;
			case 9:
				v.icon = PropertyIconResponsive;
				break;
			case 4:
				v.icon = VehicleIconResponsive;
				break;
			case 11:
				v.icon = PlanIconResponsive;
				break;
			case 12:
				v.icon = PlanIconResponsive;
				break;
			case 13:
				v.icon = PlanIconResponsive;
				break;
			case 5:
				v.icon = GeneralIconResponsive;
				break;

			default:
				v.icon = GeneralIconResponsive;
				break;
		}
		return v;
	});
	return newData;
};
