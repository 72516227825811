var format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

export const contactUsFormValid = (state, value, phrases = [], lang = 'en') => {
	if (state === 'email') {
		if (!value) {
			return phrases['lblEmailEmpty']?.[lang];
		} else if (value.length < 6) {
			return phrases['lblEmailLength']?.[lang];
		} else if (value.length > 100) {
			return phrases['lblEmailLength']?.[lang];
		} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
			return phrases['lblEnterValidEmail']?.[lang];
		} else {
			return '';
		}
	}

	if (state === 'name') {
		if (!value) {
			return phrases['lblFullNameRequired']?.[lang];
		} else if (value.length > 150) {
			return phrases['msgFullNameMinumumReg']?.[lang];
		} else if (value.length < 6) {
			return phrases['msgFullNameMinumumReg']?.[lang];
		} else if (format.test(value)) {
			return phrases?.lblNameCannotHaveSC?.[lang];
		} else {
			return '';
		}
	}

	if (state === 'message') {
		if (!value) {
			return phrases?.lblMessageIsRequired?.[lang];
		} else if (value.length > 250) {
			return phrases['lblmessagevalidation2']?.[lang];
		} else if (value.length < 6) {
			return phrases['lblmessagevalidation1']?.[lang];
		} else {
			return '';
		}
	}

	if (state === 'mobile') {
		if (!value) {
			return phrases['errorMobileNumberRequired']?.[lang];
		} else if (value.length < 8) {
			return phrases['invalimobilenumberlength']?.[lang];
		} else if (value.length > 12) {
			return phrases['invalimobilenumberlength']?.[lang];
		} else {
			return '';
		}
	}

	return true;
};

export const checkContactUsValid = (params, phrases = [], lang = 'en') => {
	const data = JSON.parse(JSON.stringify(params));
	const sendingData = { name: '', message: '', email: '', mobile: '' };

	if (!data.email) {
		sendingData.email = phrases['lblEmailEmpty']?.[lang];
	} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
		sendingData.email = phrases?.lblEnterValidEmail?.[lang];
	} else if (data.email.length < 6) {
		sendingData.email = phrases['lblEmailLength']?.[lang];
	} else if (data.email.length > 99) {
		sendingData.email = phrases['lblEmailLength']?.[lang];
	}

	if (!data.name) {
		sendingData.name = phrases['lblFullNameRequired']?.[lang];
	} else if (data.name.length < 6) {
		sendingData.name = phrases['msgFullNameMinumumReg']?.[lang];
	}
	if (format.test(data.name)) {
		sendingData.name = phrases?.lblNameCannotHaveSC?.[lang];
	}
	if (data.name.length > 150) {
		sendingData.name = 'User name length must Be less than 150 character';
	}

	if (!data.message) {
		sendingData.message = phrases?.lblMessageIsRequired?.[lang];
	} else if (data.message.length < 6) {
		sendingData.message = phrases['lblmessagevalidation1']?.[lang];
	}
	if (data.message.length > 250) {
		sendingData.message = phrases['lblmessagevalidation2']?.[lang];
	}

	if (!data.mobile) {
		sendingData.mobile = phrases['errorMobileNumberRequired'][lang];
	} else if (data.mobile.length < 8 || data.mobile.length > 12) {
		sendingData.mobile = phrases['invalimobilenumberlength']?.[lang];
	}
	return sendingData;
};
