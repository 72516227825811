import axiosInstance from './axiosInstance';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Cookies from 'js-cookie';

export default (enqueueSnackbar, locale) => {
	//For Response
	axiosInstance.interceptors.response.use(
		(res) => {
			return handleErrorInSuccessRequest(res, enqueueSnackbar, locale);
		},
		(error) => {
			return responseErrorInterceptor(error, enqueueSnackbar, locale);
		}
	);

	//For Request
	axiosInstance.interceptors.request.use((req) => {
		return requestInterceptor(req);
	});
};

const requestInterceptor = (req) => {
	return getToken()
		.then((res) => {
			if (req.method === 'get') {
				req.params = {
					...req.params,
					authtoken: res.data.authtoken,
					source: 'mweb',
				};
			} else {
				const formData = new FormData();
				Object.keys(req.data).forEach((key) => {
					formData.set(key, req.data[key]);
				});
				formData.set('authtoken', res.data.authtoken || '');
				formData.set(
					'token',
					res.data.authtoken || sessionStorage.getItem('otpToken') || ''
				);
				req.data = formData;
				req.headers['Content-Type'] = 'multipart/form-data';
			}
			return Promise.resolve(req);
		})
		.catch(() => {
			window.location.replace('/login');
		});
};

export const getToken = () => {
	// TODO we want to implement here retrieving token from authContext then verify this token with the backend
	// TODO send request with old token to server to validate on it
	const profile = localStorage.getItem('userProfile');
	return Cookies.get('authToken')
		? Promise.resolve({
				data: {
					isAuth: true,
					authtoken: Cookies.get('authToken'),
					Profile: localStorage.getItem('userProfile')
						? JSON.parse(localStorage.getItem('userProfile'))
						: {},
					DepositLimit:
						localStorage.getItem('depositLimit') &&
						localStorage.getItem('depositLimit') !== 'undefined'
							? JSON.parse(localStorage.getItem('depositLimit'))
							: [],
				},
		  })
		: Promise.resolve({
				data: {
					isAuth: false,
				},
		  });
};

const checkIfRequiredErrorInterceptor = (url) => {
	return (
		url.indexOf('/login') < 0 &&
		url.indexOf('/signup') < 0 &&
		url.indexOf('/validate') < 0
	);
};

const handleErrorInSuccessRequest = (res, enqueueSnackbar, locale) => {
	if (res?.data.Error && res?.data.Error.code) {
		const blockUrl = `${window.location.protocol}//${window.location.host}/login`;
		if (
			(res?.data.Error.code == '599' || res?.data.Error.code == '599.14') &&
			window.location.href !== blockUrl
		) {
			Cookies.remove('authToken');
			window.location.replace('/login');
		}
		if (res?.data.Error[localStorage.getItem('lang')]) {
			enqueueSnackbar(`${res.data.Error[localStorage.getItem('lang')]}`, {
				variant: 'error',
			});
		}
		return Promise.reject(res.data);
	} else {
		return res;
	}
};

const responseErrorInterceptor = (error, enqueueSnackbar, locale) => {
	if (error.response) {
		if (error.response?.status === 401 || error.response?.status === 403) {
			enqueueSnackbar(<FormattedMessage id="axios.unauthorized" />, {
				variant: 'error',
			});
		} else if (error.response.status === 404) {
			// window.location.replace(`/not-found`);
			// } else if (error.response.data && error.response.data.message) {
			// 	enqueueSnackbar(error.response?.data?.message, {
			// 		variant: 'error',
			// 	});
		}
	} else {
		// The request was made but no response was received
		// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
		// http.ClientRequest in node.js
		// enqueueSnackbar(<FormattedMessage id="axios.fault" />, {
		// 	variant: 'error',
		// });
	}
	return Promise.reject(error);
};
