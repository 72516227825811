import React, { useContext, useEffect } from 'react';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Button,
	List,
	ListItem,
	ListItemText,
	SvgIcon,
	Typography,
} from '@material-ui/core';
import navs, { authLinks } from './navs';
import { Link } from 'react-router-dom';
import Logo from '../../../assets/images/logo.png';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '../../../assets/icons/home/arrow-top.svg';

import PngIcon from '../../PngIcon';
import { auctionQueryParams } from '../../../utils/auctionFilter';
import GeneralIconResponsive from '../../../assets/icons/home/category/more.png';
import { AuthContext } from '../../../context/AuthContext';

const borderBottomActive = (pathname, path) => {
	const x = pathname.split('/')[1];
	if (path.includes(x) && x) {
		return true;
	} else if (pathname === '/' && path === '/') {
		return true;
	} else {
		return false;
	}
};
export const renderMenuList = ({ pathname, classes }) =>
	navs.slice(0, -2).map(({ key, name, path }) => (
		<Link
			to={
				path == '/auctions/4'
					? '/auctions/4?page=1&fparams=&foption=&itemperpage=100&make=&model=&year=&price=&bodytype=&search=&view=Grid'
					: path
			}
			key={key}
		>
			<Button
				color="inherit"
				classes={{ root: classes.navButton }}
				className={borderBottomActive(path, pathname) ? classes.activePath : ''}
			>
				{name}
			</Button>
		</Link>
	));

export const renderSideMenu = ({
	setSideMenuVisibility,
	pathname,
	classes,
	auctionCategories,
	others,
	plates,
	locale,
	phrases,
	auth,
}) => {
	const urlType = {
		Online: 'online',
		BuyNow: 'direct',
		Physical: 'physical',
	};

	const currentPlate = (plate) => {
		if (plate.Online.count > 0) {
			return 'Online';
		} else if (plate.BuyNow.count > 0) {
			return 'BuyNow';
		} else if (plate.Physical.count > 0) {
			return 'Physical';
		} else if (plate.APITag.indexOf('direct') > -1) {
			return 'BuyNow';
		} else if (plate.APITag.indexOf('physical') > -1) {
			return 'Physical';
		} else if (plate.APITag.indexOf('online') > -1) {
			return 'Online';
		}
		return '';
	};

	const redirectAuctions = (auctionTemplate, type, apiTag) => {
		if (auctionTemplate) {
			if (auctionTemplate == 1) {
				const selectedCat = plates[0];
				const path = `/auctions/1/${selectedCat.APITag}/${
					urlType[currentPlate(selectedCat)]
				}/${selectedCat.type}${auctionQueryParams(auctionTemplate)}`;
				return path;
			} else if (auctionTemplate == 5 && type !== 5) {
				return `/auctions/others-items/${apiTag}${auctionQueryParams(type)}`;
			} else {
				return `/auctions/${auctionTemplate}${auctionQueryParams(
					auctionTemplate
				)}`;
			}
		}
	};
	const renderDynamicLinks = (key, path, item, index) => {
		return (
			<Link
				key={key}
				to={redirectAuctions(item.auctionTemplate, item.type, item.APITag)}
				className={classes.dynamicLinks}
				onClick={() => {
					setSideMenuVisibility(false);
				}}
			>
				<ListItem
					style={{
						borderTop: index == 0 && '2px solid #E0E0E0',
						borderBottom: '2px solid #E0E0E0',
						alignItems: item.icon && 'center',
						padding: index == 0 ? '15px 0px' : '0px 0px 10px 0px',
					}}
					button
					// className={pathname === path && classes.activePathSide}
				>
					{item.icon || (item.auctionTemplate == 5 && item.type == 5) ? (
						<div>
							<PngIcon
								icon={item.icon || GeneralIconResponsive}
								width="20px"
								marginInlineEnd="15px"
							/>

							{/* <SvgIcon
								component={item.icon || GeneralIconResponsive}
								viewBox="0 0 35 35"
								className={classes.categoryIcon}
							/> */}
						</div>
					) : (
						<PngIcon icon={item?.iconURL} width="20px" marginInlineEnd="15px" />
					)}
					<ListItemText
						style={{ color: '#575757' }}
						primary={phrases[item.titlephraseKey]?.[locale]}
					/>
				</ListItem>
			</Link>
		);
	};

	return (
		<div
			role="presentation"

			// onKeyDown={() => {
			// 	setSideMenuVisibility(false);
			// }}
		>
			<div className={classes.headerResponsiveDiv}>
				<div
					className={classes.logoContainer}
					style={{ width: '65px', padding: '10px 0px' }}
				>
					<Link
						to="/"
						style={{ textDecoration: 'none' }}
						onClick={() => {
							setSideMenuVisibility(false);
						}}
					>
						<img src={Logo} alt="logo" width="19px" />
					</Link>
				</div>
				<p className={classes.txtMenu}>Menu</p>
				<div className={classes.menuIconCloseDiv}>
					<CloseIcon
						style={{ margin: '0px 20px', fontSize: '20px' }}
						onClick={() => {
							setSideMenuVisibility(false);
						}}
					/>
				</div>
			</div>

			<List>
				{authLinks(auth?.isAuth).map(({ key, name, path }) => (
					<>
						{path !== '/auctions/4' ? (
							<Link
								key={key}
								to={path}
								onClick={() => {
									setSideMenuVisibility(false);
								}}
							>
								<ListItem
									style={{
										borderBottom: '2px solid #e8e8e8',
										padding: '10px 69px',
										textAlign: 'start',
									}}
									button
									// className={pathname === path && classes.activePathSide}
								>
									<ListItemText primary={name} className={classes.fontName} />
								</ListItem>
							</Link>
						) : (
							<ListItem
								style={{
									borderBottom: '2px solid #e8e8e8',
									padding: '0px',
								}}
								button
								// className={pathname === path && classes.activePathSide}
							>
								<div className={classes.rootAccordion}>
									<Accordion>
										<AccordionSummary
											expandIcon={
												<ExpandMoreIcon style={{ margin: '0px 15px' }} />
											}
											aria-controls="panel1a-content"
											id="panel1a-header"
										>
											<Typography className={classes.heading}>
												{phrases?.btnAuctions?.[locale]}
											</Typography>
										</AccordionSummary>
										<AccordionDetails className={classes.accordionDetails}>
											{auctionCategories.map((v, i) =>
												renderDynamicLinks(key, path, v, i)
											)}
											{others.map((v) => renderDynamicLinks(key, path, v))}
											{plates &&
												plates.length &&
												renderDynamicLinks(key, path, plates[0])}
										</AccordionDetails>
									</Accordion>
								</div>
							</ListItem>
						)}
					</>
				))}
			</List>
		</div>
	);
};

export const comaFormatNumber = (number) =>
	number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
