import Axios from 'axios';
import axiosInstance from '../axios/axiosInstance';
const baseURL = process.env.REACT_APP_API_BASE_URL;

export const getFeatureItems = () =>
	axiosInstance.get('auction/feautredauction');

export const getAuctionCategories = () =>
	Axios.get(`${baseURL}auctions?source=mweb`);

export const getShoppingCart = () =>
	axiosInstance.get('account/getordersummary');

export const removeShoppingCartItem = (plateId, actionType) =>
	axiosInstance.get(
		`account/RemoveFromCart?AuctionType=${actionType}&PlateId=${plateId}`
	);

export const removeShoppingCartMobileItem = (mobileId, actionType) =>
	axiosInstance.get(
		`account/RemoveFromCart?AuctionType=${actionType}&mobileid=${mobileId}`
	);

export const getHomeState = () => axiosInstance.get(`common/homestats`);

export const getCurrentUserCode = () => {
	return Axios.get('http://ipapi.co/json/');
};
