import React, { useState, useEffect, useMemo } from 'react';
import { makeStyles } from '@material-ui/styles';
// import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import IconButton from '@material-ui/core/IconButton';
// import chatIcon from '../assets/icons/vehicle/chat.svg';
import chatIcon from '../assets/icons/vehicle/chat.png';

import { useIntl } from 'react-intl';
import { useLocation } from 'react-router';

const useStyles = makeStyles((theme) => ({
	toTop: {
		position: 'sticky',
		right: localStorage.getItem('lang') === 'en' ? '0%' : '100%',
		top: '75%',
		height: ' 50px',
		width: '50px',
		left: localStorage.getItem('lang') === 'en' ? '100%' : '0%',
		zIndex: '9999',
		marginInlineEnd: '4.5rem',
		marginBottom: '30px',
		padding: '15px 17px 16px 16px',
		boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.3)',
		backgroundColor: 'rgba(66, 74, 100, 0.8)',
		'&:hover': {
			backgroundColor: 'rgba(66, 74, 100, 0.8)',
		},
		'& svg': {
			color: '#fff',
			position: 'absolute',
		},
		[theme.breakpoints.down('sm')]: {
			marginInlineEnd: '3rem',
		},
		[theme.breakpoints.down('xs')]: {
			marginInlineEnd: '1.5rem',
		},
	},
}));
const Content = ({ children }) => {
	const classes = useStyles();
	const locale = useIntl().locale;
	const location = useLocation().pathname;
	const [isHidden, setIsHidden] = useState(false);
	const handleClick = () => {
		window[`scrollTo`]({ top: 0, behavior: `smooth` });
	};

	const handleArrowDisappearing = () => {
		location === '/login' || location === '/signup'
			? setIsHidden(true)
			: setIsHidden(false);
	};

	useEffect(() => {
		handleArrowDisappearing();
	}, [location]);

	return (
		<>
			<IconButton
				onClick={handleClick}
				className={classes.toTop}
				style={{
					display: isHidden ? 'none' : 'block',
				}}
			>
				{/* {console.log('LOC :', location)} */}
				<img src={chatIcon} />
			</IconButton>
		</>
	);
};
export default () => (
	<>
		<Content />
	</>
);
