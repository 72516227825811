import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
	inputContainer: {
		margin: '10px 0px',
		'& .MuiFormControl-root': {
			width: '80%',
		},
	},
	messageTitle: {
		textAlign: 'start',
		padding: '0px 2.5%',
		color: '#93999d',

		fontSize: '18px',
		fontWeight: 'normal',
		fontStretch: 'normal',
		fontStyle: 'normal',
		marginTop: '40px',
	},
	messageContent: {
		width: '95%',
		border: 'solid 1px rgba(138, 138, 138, 0.5)',
		borderRadius: '4px',

		fontSize: '16px',
		fontWeight: 'normal',
		fontStretch: 'normal',
		fontStyle: 'normal',
		lineHeight: 1.56,
		letterSpacing: 'normal',
		color: '#3f3f3f',
		padding: '20px',
	},
	submit: {
		boxShadow: '0 2px 7px 0 rgba(0, 132, 255, 0.19)',
		backgroundColor: '#ff422f',
		color: '#fff',
		width: '172px',
		margin: '30px 0px 80px 0px',

		fontSize: '14px',
		fontWeight: 'normal',
		fontStretch: 'normal',
		fontStyle: 'normal',
		padding: '5px',
		'&:hover': {
			backgroundColor: '#ff422f',
		},
	},
	padding: {
		padding: '0px 12%',
	},
	validation: {
		fontSize: '14px',
		fontWeight: 'normal',
		fontStretch: 'normal',
		fontStyle: 'normal',
		textAlign: 'start',
		margin: '0px 10%',
		color: '#ff422f',
	},
	validationMessage: {
		fontSize: '14px',
		fontWeight: 'normal',
		fontStretch: 'normal',
		fontStyle: 'normal',
		textAlign: 'start',
		margin: '0px 2.5%',
		color: '#ff422f',
	},
}));
