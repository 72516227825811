import React, { useEffect, useState, useContext } from 'react';
import { useStyles } from './styles';
import ProfileLogo from '../../../assets/images/header/group-7@3x.png';
import {
	useMediaQuery,
	Popover,
	Badge,
	LinearProgress,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useHistory, useLocation, useParams } from 'react-router';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { getMyDepositInfo } from '../../../api/myDeposit';
import Price from '../../Price/Price';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { AuthContext } from '../../../context/AuthContext';
import { handleProfilePath } from '../../../utils/profileSection';
import { getMessagesList } from '../../../api/messages';
import { getAllBids } from '../../../api/myBids';

const ProfileHeader = ({ data }) => {
	const isSmallScreen = useMediaQuery('(max-width:1010px)');
	const isExtraSmallScreen = useMediaQuery('(max-width:600px)');
	const [auth, saveAuth] = useContext(AuthContext);
	const currentPath = useHistory().location.pathname;
	const classes = useStyles(isSmallScreen, isExtraSmallScreen)();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [depositInfo, setDepositInfo] = useState([]);
	const [messageCount, setMessageCount] = useState(0);

	const history = useHistory();
	const location = useLocation();

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	useEffect(() => {
		getMyDepositInfo().then((Res) => {
			const reverseRes = Res.data.DepositLimit.reverse();
			setDepositInfo(reverseRes);
		});
		getMessagesList().then((Res) => {
			setMessageCount(Res?.data?.Tickets.length);
		});
	}, [anchorEl]);

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	const renderItemBiddingLimit = () => {
		return (
			<>
				<div className={classes.itemLimit}>
					{depositInfo.map((v, i) => {
						if (v.limit) {
							return (
								<div onClick={() => history.push('/auctions/my-deposits')}>
									<p>
										Available Bidding Limit{' '}
										<span>
											{v.type === 9 ? ' For Properties' : 'Excluding Proprites'}
										</span>{' '}
									</p>

									<LinearProgress
										className={classes.progress}
										variant="determinate"
										value={Math.ceil(
											(100 * Number(v.limit)) / Number(v.totallimit)
										)}
									/>

									<div style={{ marginBottom: '20px' }}>
										<Price price={v.limit} compactMode={false}></Price>
									</div>
								</div>
							);
						} else {
							return (
								<div>
									<p onClick={() => history.push('/auctions/my-deposits')}>
										Available Bidding Limit
										<span>
											{v.type === 9 ? ' For Properties' : 'Excluding Proprites'}
										</span>
									</p>
									<LinearProgress
										className={classes.progress}
										variant="determinate"
										value={0}
										onClick={() => history.push('/auctions/my-deposits')}
									/>

									<span>
										You have No Available Limit .{' '}
										<Link
											onClick={() => handleClose()}
											to={`/auctions/increase-limit/${v.type}?currentTab=credit-card`}
										>
											Make Deposit
										</Link>
									</span>
								</div>
							);
						}
					})}
				</div>
			</>
		);
	};

	const renderItemsPopver = () => {
		return (
			<>
				<Link
					to="/auctions/my-profile"
					onClick={() => handleClose()}
					className={classes.item}
				>
					<p>My Profile</p>

					<ArrowRightIcon style={{ color: '#ff422f' }} />
				</Link>

				<Link
					to="/auctions/wishlist/plate/7"
					onClick={() => handleClose()}
					className={classes.item}
				>
					<p>My wishlist</p>

					<ArrowRightIcon style={{ color: '#ff422f' }} />
				</Link>

				<Link
					to="/auctions/message"
					onClick={() => handleClose()}
					className={classes.item}
				>
					<p>
						Messages
						<Badge className={classes.badge}>{messageCount}</Badge>
					</p>

					<ArrowRightIcon style={{ color: '#ff422f' }} />
				</Link>

				<Link
					onClick={() => handleClose()}
					to="/auctions/my-deposits"
					className={classes.item}
				>
					<p>Deposits Dashboard</p>

					<ArrowRightIcon style={{ color: '#ff422f' }} />
				</Link>

				{renderItemBiddingLimit()}

				<Link
					to="/auctions/my-profile"
					onClick={() => handleClose()}
					className={classes.item}
				>
					<p> Communication Preferences</p>

					<ArrowRightIcon style={{ color: '#ff422f' }} />
				</Link>

				<Link
					to="/"
					onClick={() => {
						handleClose();
						saveAuth({ isAuth: false });
					}}
					className={classes.item}
					style={{ backgroundColor: '#2d2d2d', border: '0px' }}
				>
					<p style={{ color: '#fff' }}>Logout</p>
					<ExitToAppIcon style={{ color: '#fff' }} />
				</Link>
			</>
		);
	};
	return (
		<div style={{ zIndex: '1' }}>
			<div
				className={classes.logoDiv}
				aria-describedby={id}
				variant="contained"
				style={{ color: '#ff422f' }}
				onClick={handleClick}
				style={
					handleProfilePath(currentPath) || open
						? { borderBottom: '2px solid #ff422f' }
						: { borderBottom: '0px' }
				}
				style={
					location.pathname === '/auctions/my-profile'
						? { borderBottom: '2px solid #ff422f' }
						: { borderBottom: '0px' }
				}
			>
				<p className={classes.profileName}>{data?.name?.substring(0, 12)}</p>
				<img src={ProfileLogo} alt="profile" />
			</div>

			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				style={{ border: '1px solid #e2e2e2' }}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
			>
				{renderItemsPopver()}
			</Popover>
		</div>
	);
};

export default ProfileHeader;
