import React, { lazy, Suspense, useEffect, useState } from 'react';
import AppLayout from '../../HOC/AppLayout';
import { Route, Switch } from 'react-router-dom';
import ProtectedRoute from '../../HOC/ProtectedRoute';
import { Backdrop, CircularProgress } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import ConfigureAxiosInterceptors from '../../axios/Interceptors';

import useStyles from './styles';
import { useIntl } from 'react-intl';
import './App.css';
import ScrollTop from '../../HOC/ScrollTop';
import ContactUs from '../ContactUs';
import TermsAndContitionsPage from '../../containers/termsAndConditions';
import AuthGard from '../../HOC/AuthGard';
import Scroll from '../../HOC/Scroll';

const Home = lazy(() => import('../Home'));
const Auction = lazy(() => import('../Auction'));
const UnderConstruction = lazy(() => import('../UnderConstruction'));
const FAQs = lazy(() => import('../FAQs'));
const FAQsDetails = lazy(() => import('../FAQs/FAQsDetails'));

const NoMatch = lazy(() => import('../404'));
const Auth = lazy(() => import('../Auth'));

const App = () => {
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();
	const locale = useIntl().locale;
	const [scroll, setScroll] = useState(false);

	useEffect(() => {
		ConfigureAxiosInterceptors(enqueueSnackbar, locale);
	}, []);

	useEffect(() => {
		window.addEventListener('scroll', handleScroll, { passive: true });
	}, [scroll]);

	const handleScroll = () => {
		if (window.pageYOffset === 0) {
			setScroll(false);
		} else {
			setScroll(true);
		}
	};

	return (
		<AppLayout>
			<Suspense
				fallback={
					<Backdrop className={classes.backdrop} open>
						<CircularProgress />
					</Backdrop>
				}
			>
				<ScrollTop>
					<AuthGard>
						<div
							style={{
								position: 'relative',
								minHeight: '0px',
								marginTop: scroll ? '-80px' : '0px',
							}}
						>
							{!!scroll && <Scroll />}
							<Switch>
								<Route exact path="/" component={Home} />
								<Route path="/auctions" component={Auction} />
								<Route path="/about" component={UnderConstruction} />
								<Route path="/faqs" exact component={FAQs} />
								<Route path="/faqs/:id" exact component={FAQsDetails} />
								<Route path="/terms" component={TermsAndContitionsPage} />

								<Route path="/faq" component={UnderConstruction} />
								<Route path="/contact" component={ContactUs} />
								<Route path="/signup" component={Auth} />
								<Route path="/login" component={Auth} />
								<Route path="/otp" component={Auth} />
								<Route path="/otp-forget-password" component={Auth} />
								<Route path="/resetPassword" component={Auth} />
								<Route path="/resetPassword/:id+" component={Auth} />

								<Route path="/NewPasswordSucceed" component={Auth} />

								<Route path="/forgetPassword" component={Auth} />
								<Route path="/forgetPasswordByEmail" component={Auth} />
								<Route path="/sendEmailSuccess" component={Auth} />
								<Route path="/blockSendOtp" component={Auth} />
								<Route path="/blockScreen" component={Auth} />

								<ProtectedRoute path="/cart" component={UnderConstruction} />
								{/* <Route path="/request-plate" component={UnderConstruction} /> */}
								<Route
									path="/under-construction"
									component={UnderConstruction}
								/>
								<Route path="/not-found" component={NoMatch} />
								<Route path="*" component={NoMatch} />
							</Switch>
						</div>
					</AuthGard>
				</ScrollTop>
			</Suspense>
		</AppLayout>
	);
};

export default App;
