import React, { useState, useEffect } from 'react';

import useStyles from './styles';
import { Grid, Button } from '@material-ui/core';
import Map from '../../Auction/DepositBranches/Map';
import CallIcon from '../../../assets/icons/contact-us/call.png';
import FaxIcon from '../../../assets/icons/contact-us/fax.png';
import PinIcon from '../../../assets/icons/contact-us/location.png';
import EmailIcon from '../../../assets/icons/contact-us/email.png';
import ClockIcon from '../../../assets/images/home/fill-7.png';

const ContactUsMapSection = ({ data }) => {
	console.log(data);
	const classes = useStyles();
	const tabs = [
		'Dubai',
		'Sharjah',
		'Abu Dhabi',
		'UAQ',
		'Ajman',
		'Ras Al Khamiah',
		'Fujairah',
	];
	const [currentTab, setCurrentTab] = useState({});
	useEffect(() => {
		data.ContactUs && setCurrentTab(data?.ContactUs[0]);
	}, [data]);

	return (
		<div>
			<Grid container>
				<Grid xs={12} md={2} lg={2}>
					{data?.ContactUs?.map((v, i) => {
						if (i === 0) {
							return (
								<div
									onClick={() => setCurrentTab(v)}
									style={{
										width: '125%',
										cursor: 'pointer',
										zIndex: '9',
										position: 'relative',
										margin: '28% 0px 0px 0px',
										borderLeft:
											currentTab?.titleEn === v?.titleEn
												? '4px solid #ff422f'
												: '0px',
									}}
									className={classes.card}
								>
									{v?.titleEn}
								</div>
							);
						} else {
							return (
								<div
									onClick={() => setCurrentTab(v)}
									style={{
										width: '125%',
										cursor: 'pointer',
										zIndex: '9',
										position: 'relative',
										borderLeft:
											currentTab?.titleEn === v?.titleEn
												? '4px solid #ff422f'
												: '0px',
									}}
									className={classes.card}
								>
									{v?.titleEn}
								</div>
							);
						}
					})}
				</Grid>
				<Grid xs={12} md={7} lg={7}>
					<div
						className={(classes.card, classes.mapMobile)}
						style={{ padding: '0px', margin: '0px', height: '100%' }}
					>
						<Map
							height={'100%'}
							long={currentTab?.longitude}
							lat={currentTab?.latitude}
						></Map>
					</div>{' '}
				</Grid>
				<Grid xs={12} md={3} lg={3}>
					<div
						className={classes.card}
						style={{
							padding: '30px 25px',
							margin: '0px',
							height: '100%',
							borderRadius: '0px',
						}}
					>
						<div
							style={{
								display: 'flex',
								alignItems: 'flex-start',
								marginBottom: '40px',
							}}
						>
							<img className={classes.icon} src={PinIcon} alt={'asd'} />
							<div className={classes.info}>
								<h6>Address</h6>
								<p>{currentTab?.addressEn}</p>
								<Button
									className={classes.dir}
									onClick={() =>
										window.open(
											`https://maps.google.com/?daddr=${currentTab?.latitude},${currentTab?.longitude}`,
											'_blank'
										)
									}
								>
									Get Directions
								</Button>
							</div>
						</div>

						<div
							style={{
								display: 'flex',
								alignItems: 'flex-start',
								marginBottom: '40px',
							}}
						>
							<img className={classes.icon} src={CallIcon} alt={'asd'} />

							<div className={classes.info}>
								<h6>Telephone</h6>
								{currentTab?.telephone?.map((v) => {
									return <p>{v}</p>;
								})}
							</div>
						</div>

						<div
							style={{
								display: 'flex',
								alignItems: 'flex-start',
								marginBottom: '40px',
							}}
						>
							<img className={classes.icon} src={FaxIcon} alt={'asd'} />

							<div className={classes.info}>
								<h6>Fax</h6>
								<p>{currentTab?.fax}</p>
							</div>
						</div>

						<div
							style={{
								display: 'flex',
								alignItems: 'flex-start',
								marginBottom: '40px',
							}}
						>
							<img className={classes.icon} src={EmailIcon} alt={'asd'} />

							<div className={classes.info}>
								<h6>Email</h6>
								<p>{currentTab?.email}</p>
							</div>
						</div>

						<div
							style={{
								display: 'flex',
								alignItems: 'flex-start',
								marginBottom: '40px',
							}}
						>
							<img className={classes.icon} src={ClockIcon} alt={'asd'} />

							<div className={classes.info}>
								<h6>Working Hours</h6>
								<p>
									{currentTab?.workingHoursEn && currentTab?.workingHoursEn[0]}
								</p>
							</div>
						</div>
					</div>
				</Grid>
			</Grid>
		</div>
	);
};

export default ContactUsMapSection;
