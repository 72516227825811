import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { getToken } from '../axios/Interceptors';

const AuthContext = React.createContext([{}, () => {}]);

const AuthProvider = ({ children }) => {
	const [auth, setAuth] = useState(null);

	useEffect(() => {
		if (!auth) {
			// in case of user refresh page
			// TODO get user profile by token
			getToken()
				.then((res) => {
					setAuth(() => {
						return {
							authToken: res.data.authtoken,
							isAuth: res.data.isAuth,
							userProfile: res.data.Profile,
							depositLimit: res.data.DepositLimit,
							isActivated:
								res.data.activated ||
								Number(localStorage.getItem('isActivated')),
						};
					});
				})
				.catch((e) => {
					Cookies.remove('authToken');
					setAuth(null);
				});
		}
	}, []);

	const saveAuth = ({
		isAuth,
		authToken,
		userProfile,
		depositLimit,
		isActivated,
	}) => {
		if (isAuth) {
			Cookies.set('authToken', authToken);
			localStorage.setItem('userProfile', JSON.stringify(userProfile));
			localStorage.setItem('depositLimit', JSON.stringify(depositLimit));
			localStorage.setItem('isActivated', isActivated);
		} else {
			Cookies.remove('authToken');
			localStorage.removeItem('userProfile');
			localStorage.removeItem('depositLimit');
			localStorage.removeItem('isActivated');
		}
		setAuth(() => {
			return { isAuth, authToken, userProfile, depositLimit, isActivated };
		});
	};

	return (
		<AuthContext.Provider value={[auth, saveAuth]}>
			{children}
		</AuthContext.Provider>
	);
};

export { AuthProvider, AuthContext };
