import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ palette, breakpoints }) => ({
	root: {
		display: 'flex',
		justifyContent: 'flex-end',
		// direction: 'ltr',
		color: ({ color }) => color || '#58a722',
		'& > .MuiTypography-root': {
			'&:nth-child(1)': {
				paddingInlineEnd: '4px !important',
				fontWeight: 400,
			},
			'&:nth-child(2)': {
				fontWeight: 500,
				[breakpoints.up('lg')]: {
					fontSize: (props) => (props.compactMode ? '1.375rem' : '1.625rem'),
				},
			},
		},
	},
	rootStart: {
		display: 'flex',
		justifyContent: 'flex-start',
		// direction: 'ltr',
		color: ({ color }) => color || '#58a722',
		'& > .MuiTypography-root': {
			'&:nth-child(2)': {
				paddingInlineStart: '4px',
				fontWeight: 400,
			},
			'&:nth-child(1)': {
				fontWeight: 500,
				[breakpoints.up('lg')]: {
					fontSize: (props) => (props.compactMode ? '1.375rem' : '1.625rem'),
				},
			},
		},
	},
	currencyStyle: {
		verticalAlign: 'super',
		fontSize: '14px',
		[breakpoints.down('xs')]: {
			fontSize: '10px',
		},
	},
	priceStyle: {
		fontSize: '24px',
		fontStretch: 'normal',
		fontStyle: 'normal',
		lineHeight: 'normal',
		letterSpacing: '-1.8px',
		color: '#58a722',
		fontWeight: '400',
		[breakpoints.down('xs')]: {
			//fontSize: '15px',
			fontSize: '16px',
			fontWeight: '600',
		},
	},
}));
