import { makeStyles } from '@material-ui/core/styles';
import Image from '../../assets/images/contact-us/bitmap.png';
export default makeStyles((theme) => ({
	container: {
		paddingTop: '140px',
		[theme.breakpoints.down('xs')]: {
			paddingTop: '35px',
			paddingLeft: '20px',
			paddingRight: '20px',
		},
		[theme.breakpoints.down('md')]: {
			paddingTop: '35px',
			paddingLeft: '20px',
			paddingRight: '20px',
		},
	},
	contactImage: {
		width: '100%',
		height: '100vh',
		backgroundSize: '100% 100%',
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		backgroundImage: `url(${Image})`,
		[theme.breakpoints.down('xs')]: {
			height: 'auto !important',
			paddingBottom: '50px',
			backgroundSize: 'inherit',
			backgroundPosition: 'top',
		},
		[theme.breakpoints.down('md')]: {
			height: 'auto !important',
			paddingBottom: '50px',
			backgroundSize: 'inherit',
			backgroundPosition: 'top',
		},
	},
	logo: {
		width: '41px',
		height: '52px',
		objectFit: 'contain',
		marginBottom: '40px',
	},
	pageName: {
		width: '66px',
		height: '15px',

		fontSize: '14px',
		fontWeight: '500',
		fontStretch: 'normal',
		fontStyle: 'normal',
		lineHeight: 'normal',
		letterSpacing: 'normal',
		color: '#2d2d2d',
		margin: '0px',
	},
	ques: {
		fontSize: '60px',
		fontWeight: '500',
		fontStretch: 'normal',
		fontStyle: 'normal',
		lineHeight: 'normal',
		letterSpacing: 'normal',
		color: '#2d2d2d',
		margin: '30px 0px 0px 0px',
		[theme.breakpoints.down('xs')]: {
			fontSize: '30px',
		},
	},
	getTouch: {
		fontSize: '14px',
		fontWeight: 'normal',
		fontStretch: 'normal',
		fontStyle: 'normal',
		lineHeight: 1.57,
		letterSpacing: 'normal',
		color: '#77797e',
		margin: '0px',
		[theme.breakpoints.down('xs')]: {
			marginBottom: '15px',
		},
		[theme.breakpoints.down('md')]: {
			marginBottom: '15px',
		},
	},
	inquerySection: {
		textAlign: 'center',
		paddingTop: '50px',
		backgroundColor: '#fff',
		'& h1': {
			fontSize: '38px',
			fontWeight: '500',
			fontStretch: 'normal',
			fontStyle: 'normal',
			lineHeight: 'normal',
			letterSpacing: 'normal',
			color: '#2d2d2d',
		},
	},
	officeSection: {
		padding: '70px 0px',
		'& h1': {
			fontSize: '38px',
			fontWeight: '500',
			fontStretch: 'normal',
			fontStyle: 'normal',
			lineHeight: 'normal',
			letterSpacing: 'normal',
			color: '#2d2d2d',
			position: 'absolute',
			margin: '0px',
		},
	},
}));
