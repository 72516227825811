import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
	unitStyle: {
		// fontSize: theme.spacing(1.25),
		// verticalAlign: 'text-top',

		fontSize: '9px',
		fontWeight: '500',
		margin: '1px',
		verticalAlign: 'super',
		position: 'relative',
		bottom: localStorage.getItem('lang') === 'ar' ? '2px' : '0px',
	},
	timeLeftRenderer: {
		fontSize: theme.spacing(2.375),
	},
	divIcon: {
		display: 'flex',
		margin: '0px',
		marginInlineEnd: '4px',
		[theme.breakpoints.down('sm')]: {
			display: 'block',
			margin: '1px',
		},
	},
}));
