import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import vehiclesFilterReducer from './reducers/vehiclesFilter';
import vehiclesReducer from './reducers/vehicales';

import plansFilterReducer from './reducers/plansFilter';
import platesFilterReducer from './reducers/platesFilter';
import propertiesFilterReducer from './reducers/propertiesFilter';
import otherFilterReducer from './reducers/othersFilter';
import plateReducer from './reducers/plate';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const appReducer = combineReducers({
	vehiclesFilterReducer: vehiclesFilterReducer,
	plansFilterReducer: plansFilterReducer,
	platesFilterReducer: platesFilterReducer,
	propertiesFilterReducer: propertiesFilterReducer,
	otherFilterReducer: otherFilterReducer,
	vehiclesReducer: vehiclesReducer,
	plateReducer: plateReducer,
});

const rootReducer = (state, action) => {
	return appReducer(state, action);
};

export const store = createStore(
	rootReducer,
	composeEnhancers(applyMiddleware(thunk))
);
