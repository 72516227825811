export function paramsToObject(entries) {
	const result = {};
	for (const [key, value] of entries) {
		result[key] = value;
	}
	return result;
}

export function auctionQueryParams(type) {
	const typeId = Number(type);
	switch (typeId) {
		case 4:
			return '?page=1&fparams=&foption=&itemperpage=100&make=&model=&year=&price=&bodytype=&search=&view=Grid';
			break;
		case 1:
			return '?page=1&fparams=&foption=&itemperpage=100&price=&search=&platenumber=&view=Grid';
			break;
		case 9:
			return '?page=1&fparams=&foption=&itemperpage=100&type=&location=&search=&price=&view=List';
			break;
		case 11:
			return '?page=1&itemperpage=100&package=&search=&foption=&fparams=&price=&view=Grid';
			break;
		case 5:
			return '?page=1&itemperpage=100&price=&search=&foption=&fparams=&view=Grid';
			break;
		default:
			return '?page=1&itemperpage=100&search=&foption=&fparams=&view=Grid';
			break;
	}
}
