import { makeStyles } from '@material-ui/core/styles';

export const useStyles = (isSmallScreen, isExtraSmallScreen) =>
	makeStyles(
		({
			spacing,
			breakpoints,
			palette: { text, primary, more, secondary },
		}) => ({
			root: {
				flexGrow: 1,
			},
			menuButton: {
				marginInlineEnd: spacing(2),
				...(isSmallScreen ? { marginInlineStart: '10px' } : {}),

				...(isExtraSmallScreen ? { marginInlineStart: '10px' } : {}),
			},
			locale: {
				cursor: 'pointer',
				'&&': {
					color: text.primary,
					opacity: 0.5,
					...(isExtraSmallScreen ? { margin: '0px' } : {}),
				},
			},
			activeLocale: {
				'&&': {
					opacity: 1,
				},
			},
			input: {
				marginInlineStart: spacing(1),
				flex: 1,
			},
			iconButton: {
				padding: 10,
			},
			searchInput: {
				padding: '2px 4px',
				display: 'flex',
				alignItems: 'center',
				width: '16rem',
				marginTop: '5%',
				...(isExtraSmallScreen ? { marginInlineStart: '-40%' } : {}),
			},
			drawer: {
				'& a': {
					color: 'inherit',
					textDecoration: 'none',
				},
				'& .MuiDrawer-paper': {
					width: '100%',
					margin: '60px 0px',
					border: '1px solid #b6bfc6',
					height: '96vh',
					overflow: 'auto',
					...(isExtraSmallScreen ? { margin: '33px 0px' } : {}),
				},
			},
			activePath: {
				borderBottom: `0.1875rem solid ${primary.main}`,
			},
			activePathSide: {
				color: primary.main,
			},
			logoContainer: {
				borderInlineEnd: '1px solid rgb(226, 226, 226)',
				display: 'flex',
				alignItems: 'center',
				width: '6.3%',
				justifyContent: 'center',
				[breakpoints.down('sm')]: {
					width: '6%',
				},
				[breakpoints.down('xs')]: {
					width: '15%',
				},
			},
			header: {
				'& a': {
					color: 'inherit',
					textDecoration: 'none',
					display: 'flex',
				},

				'& button': {
					borderRadius: '0',
				},

				'& .MuiButtonBase-root': {
					...(isSmallScreen
						? { marginInlineEnd: '0px' }
						: { marginInlineEnd: '1.5625rem' }),

					fontSize: '1.125rem',
					fontWeight: '500',
					zIndex: '1',
					'&:hover': {
						borderBottom: '0.1875rem solid #ff422f',
					},
				},
			},
			navButton: {
				margin: spacing(0, 2),
			},
			extraHeader: {
				flexGrow: '1',
				display: 'flex',
				alignItems: 'normal',
				justifyContent: 'flex-end',

				'& .MuiButtonBase-root': {
					// padding: '1.25rem',
					marginInlineEnd: '0',

					'&:hover': {
						borderBottom: 0,
					},
				},

				'& .joinButton': {
					fontSize: '16px',
					background: '#fff',
					width: '9.75rem',
					...(isExtraSmallScreen ? { width: '6.75rem', fontSize: '13px' } : {}),
				},

				'& .loginButton': {
					fontSize: '16px',
					background: '#2d2d2d',
					color: '#fff',
					width: '9.75rem',
					'& span': {
						lineHeight: '1.5',
					},
					...(isExtraSmallScreen ? { width: '6.75rem', fontSize: '13px' } : {}),
				},

				'& .searchIconContained': {
					paddingRight: '25px',
					paddingLeft: '25px',
				},
			},
			spanLang: {
				...(isSmallScreen ? { fontSize: '14px', padding: '5px' } : {}),
				...(isExtraSmallScreen ? { fontSize: '12px', padding: '5px' } : {}),
			},
			spanCall: {
				...(isSmallScreen ? { fontSize: '14px', padding: '5px' } : {}),
				...(isExtraSmallScreen ? { fontSize: '10px', padding: '5px' } : {}),
			},
			topBar: {
				display: 'flex',
				alignItems: 'center',
				padding: '0.625rem 1.857rem',
				borderBottom: 'solid 1px #e2e2e2',
				...(isExtraSmallScreen
					? { justifyContent: 'space-between', padding: '0px' }
					: {}),

				'& div': {
					margin: '0',
					fontSize: '1rem',
					fontWeight: '500',
					color: '#2d2d2d',
					marginInlineEnd: '15px',
					...(isExtraSmallScreen ? { display: 'flex', fontSize: '14px' } : {}),

					'&:last-child': {
						marginInlineEnd: '0',
					},

					'&:focus': {
						outline: 'none',
					},
				},
			},
			topBarExtra: {
				flexGrow: '1',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'flex-end',
				...(isExtraSmallScreen ? { justifyContent: 'flex-end' } : {}),

				'& .MuiButtonBase-root': {
					padding: '0',
					fontWeight: '500',
					color: '#2d2d2d',
					fontSize: '15px',
					'& a': {
						direction: 'ltr',

						color: '#ff422f',
						textDecoration: 'none',
						fontSize: '15px',
					},
					'&:hover': {
						borderBottom: '0',
					},
				},
			},
			shoppingCartButton: {
				color: more.white,
				backgroundImage: 'linear-gradient(74deg, #fa2f4a 10%, #fa5f3f 95%)',
				padding: '1.5rem 2rem',
				'&:hover': {
					backgroundImage: 'linear-gradient(74deg, #fa2f4a 10%, #fa5f3f 95%)',
				},
			},

			extraNavs: {
				border: '1px solid #e2e2e2',
				padding: '1.5rem 2rem',
				[breakpoints.down('sm')]: {
					padding: '1.5rem 1.3rem',
				},
			},

			shoppingCart: {
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
			},
			timeLeft: {
				fontSize: '0.688rem',
			},
			logoDiv: {
				display: 'flex',
				cursor: 'pointer',
				height: '100%',
				alignItems: 'center',
				padding: '0px 30px',
				transition: 'all 0.2s ease',

				'& img': {
					width: '40px',
					height: '40px',
					borderRadius: '50%',
					margin: '0px 0px 0px 20px',
					...(isSmallScreen
						? {
								width: '30px',
								height: '30px',
								margin: '0px 0px 0px 10px',
						  }
						: {}),
				},
				'& p': {
					fontFamily:
						localStorage.getItem('lang') == 'en'
							? 'DINNextRoundedLTPro'
							: 'Almarai',
					fontSize: '16px',
					fontWeight: '500',
					fontStretch: 'normal',
					fontStyle: 'normal',
					lineHeight: 'normal',
					letterSpacing: 'normal',
					color: '#77797e',
				},
			},
			item: {
				display: 'flex',
				width: '350px',
				justifyContent: 'space-between',
				borderBottom: 'solid 1px #e2e2e2',
				padding: '13px 20px',
				background: '#fafbfb',
				textDecoration: 'none',
				alignSelf: 'center',

				'& p': {
					margin: '0px',
					color: 'black',
					fontWeight: '500',
					alignSelf: 'center',
				},
			},
			itemBid: {
				display: 'flex',
				width: '292px',
				justifyContent: 'space-between',
				borderBottom: 'solid 1px #e2e2e2',
				margin: '0px 30px',
				padding: '20px 0px',
				background: '#fff',
				textDecoration: 'none',
				alignSelf: 'center',
			},
			itemLimit: {
				width: '350px',
				justifyContent: 'space-between',
				borderBottom: 'solid 1px #e2e2e2',
				padding: '15px 20px',
				background: '#fff',
				'& span': {
					color: '#77797e',
					fontSize: '14px',
					'& a': {
						color: '#ff422f',
						alignSelf: 'center',
					},
				},

				'& a': {
					textDecoration: 'none',
					alignSelf: 'center',
				},
				'& p': {
					margin: '0px',
					color: 'black',
					fontWeight: '500',
					'& span': {
						fontWeight: '300',
					},
				},
			},
			progress: {
				margin: '15px 0px',
				backgroundColor: '#e2e2e2 !important',

				'& .MuiLinearProgress-barColorPrimary': {
					backgroundImage: 'linear-gradient(45deg, #87d155, #46d4ec)',
				},
			},
			badge: {
				background: '#ff422f',
				padding: '0px 10px',
				borderRadius: '33px',
				color: '#fff',
				margin: '0px 7px',
				fontSize: '13px',
				fontWeight: '400',
				verticalAlign: 'text-top',
			},
			customBadge: {
				'& .MuiBadge-colorPrimary': {
					border: '1.3px solid',
					fontSize: '13px',
					fontWeight: '400',
					verticalAlign: 'text-top',
					[breakpoints.down('sm')]: {
						fontSize: '12px',
						padding: '0px',
					},
				},
			},
			colorBidIcons: {
				color: '#b6bfc6',
				fontSize: '18px',
				margin: '-5px 10px -5px 0px',
			},
			itemBidName: {
				fontFamily:
					localStorage.getItem('lang') == 'en'
						? 'DINNextRoundedLTPro'
						: 'Almarai',
				fontSize: '15px',
				fontWeight: '500',
				fontStretch: 'normal',
				fontStyle: 'normal',
				lineHeight: 'normal',
				letterSpacing: 'normal',
				color: '#2d2d2d',
			},
			popoverBid: {
				'& .MuiPopover-paper': {
					borderRadius: '0px !important',
				},
			},
			rootAccordion: {
				width: '100%',
				'& .MuiAccordion-root': {
					boxShadow: 'none',
				},
				'& .MuiAccordionDetails-root': {
					width: '100%',
				},
			},
			accordionDetails: {
				width: '100%',
				display: 'block',
				padding: '0px',
				background: '#F3F3F3',
			},
			heading: {
				padding: '0px 59px',
				paddingInlineEnd: '10px',
				fontWeight: '500',
			},
			dynamicLinks: {
				width: '100%',
				background: '#F3F3F3',
				display: 'flex',
				padding: '15px 69px',
				'&:first-child': {
					padding: '0px 69px 15px',
				},
				'&:last-child': {
					padding: '0px 69px',
				},
			},
			headerResponsiveDiv: {
				padding: '0px',
				borderBottom: '1px solid #e8e8e8',
				display: 'flex',
			},
			txtMenu: {
				fontSize: '18px',
				fontWeight: '700',
				fontStretch: 'normal',
				fontStyle: 'normal',
				lineHeight: 'normal',
				letterSpacing: 'normal',
				color: '#2d2d2d',
				margin: '0px 21px',
				alignSelf: 'center',
			},
			menuIconCloseDiv: {
				width: '100%',
				textAlign: 'end',
				alignSelf: 'center',
			},
			categoryIcon: {
				fontSize: '40px',
				marginInlineEnd: '5px',
				verticalAlign: 'text-top',
			},
			fontName: {
				'& .MuiTypography-displayBlock': {
					fontWeight: 500,
				},
			},
			responsiveExtraNavs: {
				display: 'flex',
				justifyContent: 'space-between',
				'& button': {
					borderRadius: '1px',
					width: '33.3%',
					padding: '15px',
					background: '#fff',
				},
			},
			profileName: {
				...(isSmallScreen ? { display: 'none' } : {}),
			},
		})
	);
