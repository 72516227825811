import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
	card: {
		borderRadius: '4px',
		boxShadow: '0 2px 8px 0 rgba(36, 56, 78, 0.07)',
		backgroundColor: '#ffffff',
		margin: '20px 0px',
		padding: '20px',
	},
	mapMobile: {
		[theme.breakpoints.down('xs')]: {
			height: '600px !important',
		},
	},
	icon: {
		objectFit: 'contain',
		width: '20px',
		height: '20px',
	},
	info: {
		'& h6': {
			fontSize: '14px',
			fontHeight: 'normal',
			fontStretch: 'normal',
			fontStyle: 'normal',
			lineHeight: 'normal',
			letterSpacing: 'normal',
			color: ' #77797e',
			margin: '0px 20px',
		},
		'& p': {
			fontSize: '14px',
			fontWeight: '500',
			fontHeight: 'normal',
			fontStretch: 'normal',
			fontStyle: 'normal',
			lineHeight: '1.29',
			letterSpacing: 'normal',
			color: ' #3f3f3f',
			margin: '0px 20px',
		},
	},
	dir: {
		margin: '15px 20px 0px 20px',

		fontSize: '14px',
		fontWeight: 'normal',
		fontHeight: 'normal',
		fontStretch: 'normal',
		fontStyle: 'normal',
		lineHeight: '1.29',
		letterSpacing: 'normal',
		color: ' #fff',
		borderRadius: '4px',
		boxShadow: '0 2px 7px 0 rgba(0, 132, 255, 0.19)',
		backgroundColor: '#2d2d2d',
		width: '127px',
		'&:hover': {
			backgroundColor: '#2d2d2d',
		},
	},
}));
