import React, { useContext } from 'react';
import usePriceStyle from './PriceStyle';
import Typography from '@material-ui/core/Typography';
import numeral from 'numeral';
import { useIntl } from 'react-intl';
import { PhrasesContext } from '../../context/PhrasesContext';

const Price = ({
	currency,
	price,
	compactMode = true,
	color,
	letterSpacing,
	postion = 'flex-start',
	fontSizePrice,
	marginInlineStartForCurrency,
	fontSizeCurrency,
}) => {
	const phrases = useContext(PhrasesContext);
	const locale = useIntl().locale;
	const classes = usePriceStyle({ compactMode, color });
	const formattedPrice = numeral(price).format('0,0');
	const intl = useIntl();
	const curr = phrases['lblAED']?.[locale];

	return (
		<>
			<div
				//I removed this fixed direction which make a conflict in the vehicle grid card ==> Abdoulrhman
				className={classes.root}
				// style={{ justifyContent: postion, direction: 'ltr' }}
				style={{
					justifyContent: locale == 'en' ? 'flex-start' : 'flex-end',
					direction: 'ltr',
				}}
			>
				<Typography
					style={{
						fontSize: fontSizeCurrency && fontSizeCurrency,
						marginInlineStart:
							marginInlineStartForCurrency && marginInlineStartForCurrency,
					}}
					component="span"
					variant={compactMode ? 'subtitle2' : 'h6'}
					className={classes.currencyStyle}
				>
					{currency || curr}
				</Typography>
				<Typography
					component="span"
					style={{
						fontSize: fontSizePrice && fontSizePrice,
						letterSpacing: letterSpacing && letterSpacing,
					}}
					variant={compactMode ? 'p' : 'h3'}
					className={classes.priceStyle}
				>
					{formattedPrice}
				</Typography>
			</div>
		</>
	);
};

export default Price;
