import React, { useEffect, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import Cookies from 'js-cookie';

function AuthGard({ history, children }) {
	useEffect(() => {
		const unlisten = history.listen((e) => {
			let authToken = Cookies.get('authToken');
			if (authToken) {
				if (
					e.pathname == '/login' ||
					e.pathname == '/signup' ||
					e.pathname == '/otp-forget-password' ||
					e.pathname == '/forgetPasswordByEmail' ||
					e.pathname == '/resetPassword' ||
					e.pathname == '/forgetPassword' ||
					e.pathname == '/NewPasswordSucceed'
				) {
					history.push('/');
				}
			}
			if (!authToken) {
				if (
					e.pathname.includes('request-plate') ||
					e.pathname.includes('/my-profile') ||
					e.pathname.includes('/request-mobile') ||
					e.pathname.includes('my-deposits') ||
					e.pathname.includes('my-watchlist') ||
					e.pathname.includes('order-summary') ||
					e.pathname.includes('checkout') ||
					e.pathname.includes('sop') ||
					e.pathname.includes('auction-checkout') ||
					e.pathname.includes('message') ||
					e.pathname.includes('increase-limit') ||
					e.pathname.includes('request-refund') ||
					e.pathname.includes('my-bids') ||
					e.pathname.includes('won-items')
				) {
					history.push('/login');
				}
			}
		});
		return () => unlisten();
	}, []);

	return <Fragment>{children}</Fragment>;
}

export default withRouter(AuthGard);
