import { ACTION_TYPES } from '../actionTypes';

const initialState = {
	page: 1,
	sortoption: 'endtime',
	sortdirection: 'desc',
	fparams: '',
	foption: '',
	itemperpage: 6,
	make: '',
	model: '',
	year: '',
	price: '',
	bodytype: '',
	search: '',
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case ACTION_TYPES.UPDATE_VEHICLES_FILTERS:
			return { ...state, [action.payload.key]: action.payload.value };

		case ACTION_TYPES.CLEAR_VEHICLES_FILTERS:
			return {
				...state,
				sortoption: 'endtime',
				sortdirection: 'desc',
				fparams: '',
				foption: '',
				make: '',
				model: '',
				year: '',
				price: '',
				bodytype: '',
				search: '',
			};

		default:
			return state;
	}
};

export default reducer;
