import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
	cardContainer: {
		position: 'absolute',
		bottom: '70px',
		width: '100%',
		[theme.breakpoints.down('xs')]: {
			position: 'static',
			bottom: '0px',
		},
		[theme.breakpoints.down('md')]: {
			position: 'static',
			bottom: '0px',
		},
	},
	card: {
		borderRadius: '4px',
		height: '180px',
		boxShadow: '0 2px 8px 0 rgba(36, 56, 78, 0.07)',
		backgroundColor: '#ffffff',
		width: '100%',
		padding: '20px',
		position: 'relative',
	},
	cardGrid: {
		[theme.breakpoints.down('xs')]: {
			padding: '0px 20px 0px 20px !important',
			marginBottom: '15px',
		},
		[theme.breakpoints.down('md')]: {
			padding: '0px 20px 0px 20px !important',
			marginBottom: '15px',
		},
	},
	img: {
		margin: '10px 0px',
	},
	cardName: {
		fontSize: '18px',
		fontWeight: 'normal',
		fontStretch: 'normal',
		fontStyle: 'normal',
		lineHeight: 'normal',
		letterSpacing: 'normal',
		color: '#77797e',
		margin: '0px',
		marginBottom: '5px',
	},
	cardDesc: {
		fontSize: '18px',
		fontWeight: '500',
		fontStretch: 'normal',
		fontStyle: 'normal',
		lineHeight: 1.22,
		letterSpacing: 'normal',
		color: '#3f3f3f',
		margin: '0px',
	},
	descSection: {
		position: 'absolute',
		bottom: '20px',
	},
}));
