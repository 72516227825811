import { ACTION_TYPES } from '../actionTypes';
import produce from 'immer';
import * as _ from 'lodash';
import { RENDER_TYPE } from '../../utils/helper';

const initialState = {
	filterSelections: {
		plans: {
			header: 'plans.filter.type.title',
			type: RENDER_TYPE.RADIO_BUTTONS_WITH_ALL,
			children: {
				silver: {
					label: 'plans.filter.type.silver',
					value: false,
				},
				gold: {
					label: 'plans.filter.type.gold',
					value: false,
				},
				platinum: {
					label: 'plans.filter.type.platinum',
					value: false,
				},
				diamond: {
					label: 'plans.filter.type.diamond',
					value: false,
				},
			},
		},
		// price: {
		// 	header: 'plans.filter.price.title',
		// 	type: RENDER_TYPE.RANGE,
		// 	value: { from: '0', to: '100' },
		// },
	},
	viewType: 'Grid',
};

const updateFilters = (state, { path, value }) => {
	if (path === 'filterSelections.plans.children') {
		return produce(state, (draftState) => {
			_.set(draftState, path, {
				silver: {
					label: 'Silver',
					value: false,
				},
				gold: {
					label: 'Gold',
					value: false,
				},
				platinum: {
					label: 'Platinum',
					value: false,
				},
				diamond: {
					label: 'Diamond',
					value: false,
				},
			});
		});
	} else {
		const tmpState = produce(state, (draftState) => {
			_.set(draftState, `${path}.value`, value);
		});
		return tmpState;
	}
};

const clearFilters = (state) => {
	//TODO stupid tmp fix for now
	return {
		filterSelections: {
			plans: {
				header: 'plans.filter.type.title',
				type: RENDER_TYPE.RADIO_BUTTONS_WITH_ALL,
				children: {
					silver: {
						label: 'plans.filter.type.silver',
						value: false,
					},
					gold: {
						label: 'plans.filter.type.gold',
						value: false,
					},
					platinum: {
						label: 'plans.filter.type.platinum',
						value: false,
					},
					diamond: {
						label: 'plans.filter.type.diamond',
						value: false,
					},
				},
			},
			price: {
				header: 'plans.filter.price.title',
				type: RENDER_TYPE.RANGE,
				value: { from: '0', to: '100' },
			},
		},
	};
};

const updateViewType = (state, { viewType }) => {
	return produce(state, (draftState) => {
		draftState.viewType = viewType;
	});
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case ACTION_TYPES.AUCTION.PLANS.UPDATE_PLANS_FILTER:
			return updateFilters(state, action);
		case ACTION_TYPES.AUCTION.PLANS.CLEAR_PLANS_FILTERS:
			return clearFilters(state, action);
		case ACTION_TYPES.AUCTION.PLANS.UPDATE_VIEW_TYPE:
			return updateViewType(state, action);
		default:
			return state;
	}
};

export default reducer;
