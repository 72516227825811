import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
	modal: {
		width: '600px',
		background: '#fff',
		margin: '8% auto',
		height: '483px ',
		borderRadius: '4px',
		outline: 'none',
		[theme.breakpoints.down('sm')]: {
			height: '484px',
			width: '600px',
			margin: '50px auto',
			outline: 'none',
			background: '#fff',
			borderRadius: '4px',
		},
		[theme.breakpoints.down('xs')]: {
			width: '330px',
			height: '484px',
			margin: '100px auto',
			backgroundcolor: '#93999d',
			borderradius: '4px',
		},
	},
	modalContainer: {
		flexDirection: 'column',
		textAlign: 'center',
		margin: 'auto',
		width: '100%',
		justifyContent: 'center',
		alignItems: 'center',
		display: 'flex',
		marginTop: '16px',
	},
	/*imgResponse: {
		width: '229px !important',
		height: '233px !important',
		marginTop: '-18px !important',
		overflow: 'inherit !important',
		margin: 'auto',
		background: 'transparent',
		display: 'flex',
		objectFit: 'contain',

		[theme.breakpoints.down('xs')]: {
			marginLeft: '21px',
			marginTop: '-6px',
			width: '248px',
			height: '244px',
		},
		[theme.breakpoints.down('sm')]: {
			width: '240px !important',
			height: '243px !important',
			marginTop: '-22px !important',
		},
	},*/

	subHeaderMessage: {
		fontSize: '16px',
		fontWeight: '500',
		fontStretch: 'normal',
		fontStyle: 'normal',
		lineHeight: 'normal',
		letterSpacing: 'normal',
		textAlign: 'center',
		color: '#3f3f3f',
		marginTop: '20px',
	},
	btnPrimary: {
		fontSize: '14px',
		fontWeight: '500',
		fontStretch: 'normal',
		fontStyle: 'normal',
		lineHeight: 'normal',
		letterSpacing: 'normal',
		textAlign: 'center',
		color: '#ffffff',
		borderRadius: '4px',
		boxShadow: '0 2px 7px 0 rgba(0, 132, 255, 0.19)',
		backgroundColor: '#ff422f',
		width: '175px',

		[theme.breakpoints.down('xs')]: {
			width: '175px',
			height: '50px',
			padding: '17px 26px 17px 27px',
			borderradius: '4px',
			backgroundcolor: '#ff422f',
		},
		[theme.breakpoints.down('sm')]: {
			width: '175px',
			height: '50px',
			borderradius: '4px',
			backgroundcolor: '#ff422f',
			boxshadow: '0 2px 7px 0 rgba(255, 0, 0, 0.19)',
		},
		'&:hover': {
			color: '#ffffff',
			backgroundColor: '#ff422f',
		},
		btnDiv: {
			display: 'flex',
			justifyContent: 'space-around',
			'& button': {
				fontSize: '14px',
				fontWeight: '500',
				fontStretch: 'normal',
				fontStyle: 'normal',
				lineHeight: 'normal',
				letterSpacing: 'normal',
				textAlign: 'center',
				color: '#ffffff',
				borderRadius: '4px',
				margin: '0px 10px',
				width: '170px',

				[theme.breakpoints.down('sm')]: {
					width: '100%',
				},
			},
		},
		btnBlack: {
			backgroundColor: '#2d2d2d',
		},
		iconContainer: {
			textAlign: 'end',
		},

		closeIcon: {
			color: '#ff422f',
			margin: '5px',
			position: 'relative',
			top: '20px',
			left: '-25px',
			[theme.breakpoints.down('xs')]: {
				color: '#ff422f',
				margin: '5px',
				position: 'relative',
				top: '11px',
				left: '-13px',
			},
		},
	},
}));
