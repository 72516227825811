import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
	header: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginInlineStart: '1.75rem',
		color: theme.palette.more.blueRinse,
		marginTop: theme.spacing(1.5),
	},
	shoppingCartDialog: {
		'& .MuiPopover-paper': {
			boxShadow: '0 3px 0 0 #ff422f',
			backgroundColor: '#fafbfb',
			borderRadius: '0px',
		},
	},
	shoppingCartContent: {
		width: '440px',
		maxHeight: '100%',
		minHeight: '182px',
		overflow: 'auto',
		paddingRight: '0 !important',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	shoppingCartCardContent: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		paddingBottom: '0.875rem !important',
		paddingRight: '0 !important',
	},
	card: {
		display: 'flex',
		padding: '0.75rem 0 0.75rem 0.75rem',
		marginBottom: '1.75rem',
		marginInlineStart: '1.3rem',
		marginInlineEnd: '1.75rem',
	},
	actions: {
		textAlign: 'center',
		marginTop: '0.313rem',
		marginBottom: '1.688rem',
	},
	shoppingCartPrice: {
		display: 'flex',
		justifyContent: 'center',
		marginBottom: '0.5rem',
		'& .MuiTypography-root': {
			fontSize: '1.625rem',
			color: theme.palette.more.greenFlash,
		},
	},
	drawerRoot: {
		height: '100%',
	},
	drawerPaper: {
		height: '100%',
		position: 'unset',
		backgroundColor: theme.palette.more.drWhite,
	},
	shoppingList: {
		'&:last-child': {
			paddingBottom: '0',
			paddingTop: '20px',
		},
	},
	removeButton: (props) => ({
		paddingRight: theme.spacing(2),
		paddingLeft: props.isSmallScreen ? '0.5rem' : '2rem',
	}),
	tab: {
		border: 'solid 1px #e2e2e2',
		backgroundColor: '#ffffff',
		textAlign: 'center',
		cursor: 'pointer',
	},
	tabNonActive: {
		border: 'solid 1px #e2e2e2',
		backgroundColor: 'transparent',
		textAlign: 'center',
		cursor: 'pointer',
	},
	tabName: {
		fontSize: '15px',
		fontWeight: '500',
		fontStretch: 'normal',
		fontStyle: 'normal',
		lineHeight: 'normal',
		letterSpacing: 'normal',
		color: '#1a58b8',
		margin: '0px',
		marginTop: '20px',
		marginBottom: '5px',
	},
	tabNonActiveName: {
		fontSize: '15px',
		fontWeight: '500',
		fontStretch: 'normal',
		fontStyle: 'normal',
		lineHeight: 'normal',
		letterSpacing: 'normal',
		color: '#77797e',
		margin: '0px',
		marginTop: '20px',
		marginBottom: '5px',
	},
	tabCount: {
		fontSize: '14px',
		fontWeight: '500',
		fontStretch: 'normal',
		fontStyle: 'normal',
		lineHeight: 'normal',
		letterSpacing: 'normal',
		color: '#77797e',
		margin: '0px',
		marginBottom: '20px',
	},

	PlatesImgDiv: {
		width: '30px',
		height: '30px',
		backgroundImage: 'linear-gradient(to bottom, #fe8695, #ff73b4 100%)',
		borderRadius: '50%',
		margin: '0px auto',
		textAlign: 'center',
		'& img': {
			verticalAlign: 'sub',
		},
	},
	PlansImgDiv: {
		width: '30px',
		height: '30px',
		backgroundImage:
			'linear-gradient(to bottom, #8ae47a, #55df8a 54%, #27db97)',
		borderRadius: '50%',
		margin: '0px auto',
		textAlign: 'center',
		'& img': {
			verticalAlign: '-webkit-baseline-middle',
			objectFit: 'contain',
			width: '20px',
		},
	},
	itemCart: {
		padding: '20px 0px',
		backgroundColor: '#ffffff',
		width: '100%',
		borderRadius: '4px',
		boxShadow: '0 0 6px 0 rgba(227, 228, 232, 0.89)',
		position: 'relative',
		paddingBottom: '0px',
		height: '100%',
		cursor: 'pointer',
	},
	type: {
		fontSize: '15px',
		fontWeight: '500',
		fontStretch: 'normal',
		fontStyle: 'normal',
		lineHeight: 'normal',
		letterSpacing: 'normal',
		color: '#2d2d2d',
		textAlign: 'center',
		marginBottom: '0px',
	},
	itemType: {
		fontSize: '14px',
		fontWeight: '500',
		fontStretch: 'normal',
		fontStyle: 'normal',
		lineHeight: 'normal',
		letterSpacing: 'normal',
		textAlign: 'center',
		color: '#77797e',
		margin: '0px',
	},
	timeLeft: {
		fontSize: '0.688rem',
		textAlign: 'center',
		backgroundColor: 'rgba(182, 191, 198, 0.1)',
		padding: '8px',
		color: '#ff422f',
	},
	timeLeftColock: {
		fontSize: '0.688rem',
		textAlign: 'center',
		padding: '8px',
		color: '#ff422f',
	},
	exText: {
		margin: '30px 0px 20px 0px',

		fontSize: '13px',
		fontWeight: 'normal',
		fontStretch: 'normal',
		fontStyle: 'normal',
		lineHeight: 'normal',
		letterSpacing: 'normal',
		textAlign: 'center',
		color: '#77797e',
	},
	checkBtn: {
		width: '88%',
		marginBottom: '40px',
		fontWeight: 'normal',
	},
	footer: {
		width: '100%',
		textAlign: 'center',
		marginTop: '20px',
	},
	imgNoAct: {
		height: '231px',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			padding: '0px 20px',
		},
	},
	hImgNoAct: {
		fontSize: '24px',
		fontWeight: '500',
		fontStretch: 'normal',
		fontStyle: 'normal',
		lineHeight: 'normal',
		letterSpacing: 'normal',
		textAlign: 'center',
		color: '#3f3f3f',
		marginBottom: '10px',
	},
	pImgNoAct: {
		fontSize: '18px',
		fontWeight: 'normal',
		fontStretch: 'normal',
		fontStyle: 'normal',
		lineHeight: 'normal',
		letterSpacing: 'normal',
		textAlign: 'center',
		color: '#77797e',
		padding: '0px 20px',
		margin: '0px 50px',
	},
}));
