import { FormattedMessage } from 'react-intl';
import React from 'react';

const paths = {
	pathLogin: '/login',
	pathSignup: '/signup',
	pathAuctions: '/auctions/4',
	pathAbout: '/about',
	pathContactUS: '/contact',
	pathFaqs: '/faqs',
	pathHome: '/',
};

export default [
	{
		key: 'home',
		name: <FormattedMessage id="header.navigation.home" />,
		path: paths.pathHome,
	},
	{
		key: 'auctions',
		name: <FormattedMessage id="header.navigation.auctions" />,
		path: paths.pathAuctions,
		children: [
			{
				key: 'cars',
				name: 'Cars',
				children: [
					{
						key: 'plates',
						name: 'Plates',
					},
				],
			},
			{
				key: 'house',
				name: 'Houses',
				path: '/houses',
			},
		],
	},
	{
		key: 'about',
		name: <FormattedMessage id="header.navigation.about" />,
		path: paths.pathAbout,
	},
	{
		key: 'faqs',
		name: <FormattedMessage id="header.navigation.faqs" />,
		path: paths.pathFaqs,
	},
	{
		key: 'contactUs',
		name: <FormattedMessage id="header.navigation.contactUs" />,
		path: paths.pathContactUS,
	},
	{
		key: 'login',
		name: <FormattedMessage id="header.auth.login" />,
		path: paths.pathLogin,
	},
	{
		key: 'signup',
		name: <FormattedMessage id="header.button.joinUs" />,
		path: paths.pathSignup,
	},
];

export const authLinks = (auth) => {
	if (!auth) {
		return [
			{
				key: 'home',
				name: <FormattedMessage id="header.navigation.home" />,
				path: paths.pathHome,
			},
			{
				key: 'auctions',
				name: <FormattedMessage id="header.navigation.auctions" />,
				path: paths.pathAuctions,
				children: [
					{
						key: 'cars',
						name: 'Cars',
						children: [
							{
								key: 'plates',
								name: 'Plates',
							},
						],
					},
					{
						key: 'house',
						name: 'Houses',
						path: '/houses',
					},
				],
			},
			{
				key: 'about',
				name: <FormattedMessage id="header.navigation.about" />,
				path: paths.pathAbout,
			},
			{
				key: 'faqs',
				name: <FormattedMessage id="header.navigation.faqs" />,
				path: paths.pathFaqs,
			},
			{
				key: 'contactUs',
				name: <FormattedMessage id="header.navigation.contactUs" />,
				path: paths.pathContactUS,
			},
			{
				key: 'login',
				name: <FormattedMessage id="header.auth.login" />,
				path: paths.pathLogin,
			},
			{
				key: 'signup',
				name: <FormattedMessage id="header.button.joinUs" />,
				path: paths.pathSignup,
			},
		];
	} else {
		return [
			{
				key: 'home',
				name: <FormattedMessage id="header.navigation.home" />,
				path: paths.pathHome,
			},
			{
				key: 'auctions',
				name: <FormattedMessage id="header.navigation.auctions" />,
				path: paths.pathAuctions,
				children: [
					{
						key: 'cars',
						name: 'Cars',
						children: [
							{
								key: 'plates',
								name: 'Plates',
							},
						],
					},
					{
						key: 'house',
						name: 'Houses',
						path: '/houses',
					},
				],
			},
			{
				key: 'about',
				name: <FormattedMessage id="header.navigation.about" />,
				path: paths.pathAbout,
			},
			{
				key: 'faqs',
				name: <FormattedMessage id="header.navigation.faqs" />,
				path: paths.pathFaqs,
			},
			{
				key: 'contactUs',
				name: <FormattedMessage id="header.navigation.contactUs" />,
				path: paths.pathContactUS,
			},
		];
	}
};
