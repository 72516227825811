import { makeStyles } from '@material-ui/core/styles';
export default makeStyles((theme) => ({
	termHeader: {
		margin: '70px 0px 30px 0px',
		// width: '548px',
		height: '39px',
		fontSize: '30px',
		fontWeight: '500',
		fontStretch: 'normal',
		fontStyle: 'normal',
		lineHeight: 'normal',
		letterSpacing: 'normal',
		color: '#2d2d2d',
		padding: '10px 40px !important',
		textAlign: 'center',
		[theme.breakpoints.down('sm')]: {
			margin: '70px 35px 30px 0px !important',
		},
		[theme.breakpoints.down('xs')]: {
			fontSize: '22px',
			margin: '88px 18px 0px 16px !important',
			padding: '0px !important',
			textAlign: 'center',
		},
	},
	content: {
		overflow: 'auto',
		height: '80vh',
		color: '#000',
		paddingInlineStart: '15px',
		[theme.breakpoints.down('xs')]: {
			margin: '0px 10px',
		},
		'& div': {
			padding: '10px 48px !important',
			[theme.breakpoints.down('xs')]: {
				padding: '10px !important',
			},
		},
		'&::-webkit-scrollbar': {
			width: '4px',
		},
		'&::-webkit-scrollbar-track': {
			'-webkit-box-shadow': 'inset 0 0 6px #fff',
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundColor: '#d8d8d8',
			// outline: '1px solid slategrey',
			borderRadius: '20px',
		},
		'&&': {
			padding: `${theme.spacing(0, 0)} !important`,
			flexDirection: 'column !important',
		},
	},
	container: {
		minHeight: '100vh',
		marginTop: '-40px',
	},
}));
