import React, { useContext, useEffect, useState } from 'react';
import { getShoppingCart } from '../api/home';
import { AuthContext } from './AuthContext';
import { getCheckouts } from '../api/checkout-auction';
import { useLocation } from 'react-router';

const ShoppingCartContext = React.createContext([{}, () => {}]);

const ShoppingCartProvider = ({ children }) => {
	const [shoppingCart, setShoppingCart] = useState({});
	const [shoppingCartCount, setShoppingCartCount] = useState(0);
	const [auctionCheckouts, setAuctionCheckouts] = useState([]);

	const [auth] = useContext(AuthContext);

	const location = useLocation();
	const incrementDecrementCartCount = (step) =>
		setShoppingCartCount((prevState) => prevState + step);

	useEffect(() => {
		if (auth?.isAuth) {
			getShoppingCart().then((res) => {
				if (res?.data) {
					setShoppingCart(res.data);
					if (res?.data?.count !== shoppingCartCount) {
						setShoppingCartCount(res?.data?.count);
					}
				}

				getCheckouts() &&
					getCheckouts().then((Res) => {
						setAuctionCheckouts(Res?.data?.MyCheckOut || []);
					});
			});
		}
	}, [auth?.isAuth, shoppingCartCount, location]);

	return (
		<ShoppingCartContext.Provider
			value={[shoppingCart, incrementDecrementCartCount, auctionCheckouts]}
		>
			{children}
		</ShoppingCartContext.Provider>
	);
};

export { ShoppingCartProvider, ShoppingCartContext };
