import React, { useState, useEffect } from 'react';
import useStyles from './styles';
import LogoImage from '../../assets/bitmap.png';
import { Container, Modal } from '@material-ui/core';
import HeaderCards from '../../components/ContactUs/headerCards';
import ContactUsInputs from '../../components/ContactUs/inquery';
import ContactUsMapSection from '../../components/ContactUs/map';
import ResponseInquery from '../../components/ContactUs/responseContactUs';
import { getContactUs } from '../../api/contactUs';
const ContactUs = () => {
	const classes = useStyles();
	const [modaIsOpen, setModalIsOpen] = useState(false);
	const [contactUs, setContactUs] = useState({});
	const handleCloseModal = () => setModalIsOpen(false);
	const [response, setResponse] = useState('');

	useEffect(() => {
		getContactUs().then((Res) => setContactUs(Res.data));
	}, []);

	return (
		<>
			<div className={classes.headerSection}>
				<div className={classes.contactImage}>
					<Container style={{ position: 'relative', height: '100%' }}>
						<div className={classes.container}>
							<img src={LogoImage} className={classes.logo} alt="logo" />
							<p className={classes.pageName}>Contact Us</p>
							<h2 className={classes.ques}>Have questions?</h2>
							<p className={classes.getTouch}>
								Get in touch with our team using any of the following channels
							</p>
						</div>

						<HeaderCards data={contactUs}></HeaderCards>
					</Container>
				</div>
			</div>
			<div className={classes.inquerySection}>
				<Container>
					<h1>For Inquries, drop us a line</h1>
					<ContactUsInputs
						inqueryStatus={(status) => {
							setResponse(status);
							setModalIsOpen(true);
						}}
					></ContactUsInputs>
				</Container>
			</div>

			<div className={classes.officeSection}>
				<Container>
					<h1>Our Offices</h1>
					<ContactUsMapSection data={contactUs}></ContactUsMapSection>
				</Container>
			</div>

			<Modal
				BackdropProps={{
					style: { background: 'rgba(0, 0, 0, 0.3)' },
					timeout: 500,
				}}
				open={modaIsOpen}
				onClose={() => handleCloseModal()}
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
			>
				<ResponseInquery
					response={response}
					changeModal={() => handleCloseModal()}
				></ResponseInquery>
			</Modal>
		</>
	);
};

export default ContactUs;
