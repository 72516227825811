import axiosInstance from '../axios/axiosInstance';
import { auctionTypeName } from '../utils/autionType';
import axios from 'axios';
import Cookies from 'js-cookie';
const baseURL = process.env.REACT_APP_API_BASE_URL;

export const getOthersSop = () => {
	let authToken = Cookies.get('authToken');
	return axios.get(
		`${baseURL}checkout/surplus?source=mweb&authtoken=${authToken}`
	);
};
export const getAllSop = (apiTag) => {
	return axiosInstance.get(`checkout/${apiTag}?source=mweb`);
};

export const getCheckoutForSingleSop = (id, sopId) => {
	return axiosInstance.get(`checkout/${id}/getauctiondetails?aid=${sopId}`);
};

export const getCheckouts = () => {
	const token = Cookies.get('authToken');
	if (token) {
		return axiosInstance.get(`checkout`);
	}
	return null;
};

export const getAllPlatesSops = (id) => {
	const typeName = auctionTypeName(Number(id));
	return axiosInstance.get(`checkout/${typeName}/checkoutlist`);
};

export const getPlatesOrderForSingleSop = (auctionType, sopId) => {
	const typeName = auctionTypeName(Number(auctionType));

	return axiosInstance.get(
		`${baseURL}checkout/${typeName}/checkoutdetail?auctionid=${auctionType}&sopId=${sopId}`
	);
};
export const getPaymentHistoryCheckout = () => {
	return axiosInstance.get(`checkout/cars/listuploadreceipts`);
};
export const uploadReceiptPayment = (image) =>
	axiosInstance.post(`account/uploadreceipt`, image);

export const selectOwnerForSinglePlate = (auctionType, id, itemId) => {
	const typeName = auctionTypeName(Number(auctionType));

	return axiosInstance.get(
		`/checkout/${typeName}/selectowner?id=${id}&itemid=${itemId}`
	);
};
export const selectAddressForSinglePlate = (auctionType, id) => {
	const typeName = auctionTypeName(Number(auctionType));
	return axiosInstance.get(`/checkout/${typeName}/selectaddress?id=${id}`);
};
