import React, { useContext, useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import Countdown from 'react-countdown';
import useStyles from './styles';
import ClockIcon from '../../assets/icons/clock-bidding.svg';
//import ClockIcon from '../../assets/icons/vehicle/group-14-copy-2.svg';
import AccessAlarmIcon from '../../assets/icons/auction/clock-2.png';
import * as moment from 'moment';
import { PhrasesContext } from '../../context/PhrasesContext';
import { useIntl } from 'react-intl';
export default function TimeDown({
	seconds,
	unitStyle,
	timeStyle,
	sperator,
	showLetters = true,
	startText,
	noWrap,
	upperCase = true,
	removeEnding = false,
	changeIcon,
	showIcon,
}) {
	const classes = useStyles();
	const [renderEnding, setRenderEnding] = useState(false);
	const [phrases] = useContext(PhrasesContext);
	const locale = useIntl().locale;

	const rednderTime = () => {
		if (!removeEnding) {
			return (
				<>
					{' '}
					{!renderEnding ? (
						<Countdown
							date={Date.now() + seconds}
							renderer={timeLeftRenderer}
							onComplete={() => setRenderEnding(true)}
							onMount={() => {
								const formatted = moment(Date.now() + seconds).format(
									'YYYY-MM-DD HH:mm:ss'
								);
								const deffSec = moment().diff(formatted, 'seconds');
								deffSec ? setRenderEnding(false) : setRenderEnding(true);
							}}
						/>
					) : (
						<span
							style={
								locale == 'en' ? { color: '#ff422f' } : { color: '#ff422f' }
							}
						>
							{phrases?.lblEnding?.[locale]}
						</span>
					)}
				</>
			);
		} else {
			return (
				<Countdown date={Date.now() + seconds} renderer={timeLeftRenderer} />
			);
		}
	};
	function timeLeftRenderer({ days, hours, minutes, seconds }) {
		return (
			<>
				{showIcon && (
					<span className={classes.divIcon}>
						{!removeEnding && !hours && minutes < 5 && !days ? (
							<div className={classes.divIcon}>
								<img src={AccessAlarmIcon} alt="imageClock" />
							</div>
						) : (
							<div className={classes.divIcon}>
								<ClockIcon className={classes.clockIcon} />
							</div>
						)}
					</span>
				)}

				<Typography
					className={timeStyle ? timeStyle : classes.timeLeftRenderer}
					component="span"
					variant="h6"
					noWrap={noWrap}
					//style={{ direction: locale == 'ar' ? 'ltr' : 'rtl' }}
				>
					{startText}{' '}
					{days > 0 ? (
						<span
							style={{ fontSize: timeStyle?.fontSize && timeStyle?.fontSize }}
						>
							{`${days <= 9 ? 0 : ''}${days}`}
							{days && (
								<span
									style={{
										textTransform: upperCase && 'uppercase',
									}}
									className={unitStyle ? unitStyle : classes.unitStyle}
								>
									{locale === 'en' ? 'd' : 'ي'}
								</span>
							)}
							{sperator}
						</span>
					) : (
						''
					)}
					{hours > 0 || days > 0 ? (
						<span
							style={{ fontSize: timeStyle?.fontSize && timeStyle?.fontSize }}
						>
							{`${hours <= 9 && !!hours ? 0 : ''}${hours || ''}`}
							{`${!!hours ? '' : '00'}`}

							{(hours > 0 || days > 0) && (
								<span
									style={{
										textTransform: upperCase && 'uppercase',
									}}
									className={unitStyle ? unitStyle : classes.unitStyle}
								>
									{locale === 'en' ? 'h' : 'س'}
								</span>
							)}
							{sperator}
						</span>
					) : (
						!!!hours && (
							<span
								style={{
									fontSize: timeStyle?.fontSize && timeStyle?.fontSize,
									color:
										!removeEnding &&
										!hours &&
										minutes < 5 &&
										!days &&
										'#ff422f',
								}}
							>
								00
								<span
									style={{
										textTransform: upperCase && 'uppercase',
									}}
									className={unitStyle ? unitStyle : classes.unitStyle}
								>
									{locale === 'en' ? 'h' : 'س'}
								</span>
								{sperator}
							</span>
						)
					)}
					{minutes > 0 ? (
						<span
							style={{
								fontSize: timeStyle?.fontSize && timeStyle?.fontSize,
								color:
									!removeEnding && !hours && minutes < 5 && !days && '#ff422f',
							}}
						>
							{`${minutes <= 9 && !!minutes ? 0 : ''}${minutes || ''}`}

							{`${!!minutes ? '' : '00'}`}

							{minutes > 0 && (
								<span
									style={{
										textTransform: upperCase && 'uppercase',
									}}
									className={unitStyle ? unitStyle : classes.unitStyle}
								>
									{locale === 'en' ? 'm' : 'د'}
								</span>
							)}
							{days || !minutes ? '' : sperator}
						</span>
					) : (
						!!!minutes && (
							<span
								style={{
									fontSize: timeStyle?.fontSize && timeStyle?.fontSize,
									color:
										!removeEnding &&
										!hours &&
										minutes < 5 &&
										!days &&
										'#ff422f',
								}}
							>
								{`${minutes <= 9 ? 0 : ''}${minutes || '0'}`}
								<span
									style={{
										textTransform: upperCase && 'uppercase',
									}}
									className={unitStyle ? unitStyle : classes.unitStyle}
								>
									{locale === 'en' ? 'm' : 'د'}
								</span>

								{sperator}
							</span>
						)
					)}
					{!days && (hours > 0 || minutes > 0 || seconds > 0) && (
						<span
							style={{
								fontSize: timeStyle?.fontSize && timeStyle?.fontSize,
								color:
									!removeEnding && !hours && minutes < 5 && !days && '#ff422f',
							}}
						>
							{`${seconds <= 9 && seconds > 0 ? 0 : ''}${seconds || '00'}`}
							{(seconds > 0 || hours > 0 || minutes > 0) && (
								<span
									style={{
										textTransform: upperCase && 'uppercase',
									}}
									className={unitStyle ? unitStyle : classes.unitStyle}
								>
									{locale === 'en' ? 's' : 'ث'}
								</span>
							)}
						</span>
					)}
				</Typography>
			</>
		);
	}

	return <> {rednderTime()}</>;
}
