import { CircularProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { getPhrases } from '../api/common';
import { normalize } from '../utils/helper';

const PhrasesContext = React.createContext([{}, () => {}]);

const PhrasesProvider = ({ children }) => {
	const [phrases, setPhrases] = useState({});

	useEffect(() => {
		getPhrases().then((res) => {
			if (res?.data?.Phrases?.length > 0) {
				setPhrases(normalize(res?.data.Phrases, 'key'));
			}
		});
	}, []);

	return (
		<PhrasesContext.Provider value={[phrases, setPhrases]}>
			{Object.keys(phrases).length === 0 && phrases.constructor === Object ? (
				<div
					style={{
						height: '100vh',
						width: '100%',
						zIndex: '2',
						position: 'fixed',
						background: 'rgba(0,0,0,0.2)',
						textAlign: 'center',
						padding: '20% 0px',
					}}
				>
					{/* <CircularProgress style={{ color: 'red' }} size={50} /> */}
				</div>
			) : (
				<div style={{ background: 'transparent' }}></div>
			)}
			{children}
		</PhrasesContext.Provider>
	);
};

export { PhrasesProvider, PhrasesContext };
