import { makeStyles } from '@material-ui/core/styles';
import { rgba } from 'polished';

export const useStyles = ({ isSmallScreen, isExtraSmallScreen }) =>
	makeStyles(({ palette: { more } }) => ({
		container: {
			backgroundColor: more.jetBlack,
		},
		section: {
			color: '#fff',
			borderBottom: `1px solid ${rgba(more.blueRinse, 0.1)}`,
			padding: `1.5rem ${isExtraSmallScreen ? '1.5rem' : `5.5625rem`}`,
			flexWrap: 'wrap',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			paddingTop: '2.625rem',
			paddingBottom: '2.625rem',
			...(isExtraSmallScreen ? { padding: '25px' } : {}),

			'& input': {
				width: '23.438rem',
				height: '2.625rem',
				borderRadius: '4px',
				backgroundColor: '#b6bfc61a',
				fontSize: '14px',
				color: '#fff',
				padding: '0 14px',
				marginInlineEnd: '1.25rem',
				...(isExtraSmallScreen ? { width: '100%' } : {}),
			},

			'& button': {
				width: '25%',
				maxWidth: '184px',
				minWidth: 'fit-content',
				height: '42px',
				borderRadius: '4px',
				border: 'solid 1px #b6bfc6',
				color: '#b6bfc6',
			},
		},
		gridItemCustom: {
			...(isExtraSmallScreen ? { padding: '0 2.5rem !important' } : {}),
		},
		auctionsList: {
			...(isExtraSmallScreen ? { margin: '15px -40px' } : {}),
		},
		moreInfo: {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'flex-start',
			fontSize: '1rem',
			lineHeight: 2.25,
			color: more.tarnishedSilver,
			paddingTop: '3rem',
			paddingBottom: '3rem',
			...(isSmallScreen ? { paddingBottom: '0rem' } : {}),
			...(isExtraSmallScreen
				? { paddingBottom: '0rem', paddingTop: '0rem' }
				: {}),

			'& p': {
				margin: 0,
				fontSize: '1em',
				width: 'max-content',
				cursor: 'pointer',
			},
			'& > div': {
				...(isSmallScreen
					? { marginBottom: '0rem', paddingBottom: '0rem' }
					: {}),
			},
		},
		credits: {
			textAlign: 'center',
			fontSize: '1rem',
		},
		sectionTitle: {
			fontSize: '1.375rem',
			color: '#fff',
			marginBottom: '10px',
			...(isExtraSmallScreen ? { margin: '20px 0px' } : {}),
		},
		listSpacing: {
			marginTop: '35px',
		},
		newsletterPart: {
			'& .MuiInputBase-root': {
				...(isExtraSmallScreen ? { width: '100% !important' } : {}),
			},
			flexGrow: 0.5,
			...(isSmallScreen ? { display: 'flex', justifyContent: 'center' } : {}),
			...(isExtraSmallScreen
				? {
						width: '100%',
						flexDirection: 'row',
						'& input': {
							width: '100% !important;',
						},
						'& button': {
							marginTop: '0rem',
						},
				  }
				: {}),
		},
		socialIcon: {
			border: '1px solid #fff',
			borderRadius: '50%',
			width: '2.87500rem',
			height: '2.87500rem',
			paddingLeft: '0.5rem',
			paddingTop: '0.5rem',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		socialIconsWrapper: {
			display: 'flex',
			margin: '30px 0px',
			...(isExtraSmallScreen ? { justifyContent: 'flex-start' } : {}),
			// [theme.breakpoints.down('sm')]: {
			// 	marginLeft: '100px',
			// },
		},

		socialIconsDownloads: {
			display: 'flex',
		},

		horizontalLogo: {
			textAlign: 'start',
		},

		logos: {
			display: 'flex',
			alignItems: 'center',
			flexGrow: '1',
			justifyContent: 'center',
			...(isSmallScreen
				? { marginTop: '1rem', justifyContent: 'flex-start' }
				: {}),

			'& img': {
				marginInlineEnd: '3rem',
				...(isExtraSmallScreen ? { marginInlineEnd: '2rem' } : {}),

				'&:last-of-type': {
					marginInlineEnd: '0',
				},
			},
		},
		logoFooterDiv: {
			textAlign: 'center',
			...(isExtraSmallScreen ? { textAlign: 'start', margin: '20px 0px' } : {}),
		},
	}));
