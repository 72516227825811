import { ACTION_TYPES } from '../actionTypes';

const initialState = {
	viewType: 'Grid',
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case ACTION_TYPES.AUCTION.OTHERS.UPDATE_VIEW_TYPE:
			return { ...state, viewType: action.viewType };
		default:
			return state;
	}
};

export default reducer;
