import React from 'react';

export default function PngIcon({ icon, width, height, marginInlineEnd }) {
	return (
		<img
			style={{
				display: 'block',
				width: `${width ? width : 'auto'}`,
				height: `${height ? height : 'auto'}`,
				marginInlineEnd: `${marginInlineEnd ? marginInlineEnd : '0px'}`,
			}}
			alt="icon"
			src={icon}
		/>
	);
}
