import React, { useState, useEffect, useContext } from 'react';
import useStyles from './styles';
import { Container, Typography, CircularProgress } from '@material-ui/core';
import { getTermsAndConditions } from '../../api/common';
import { useIntl } from 'react-intl';
import { PhrasesContext } from '../../context/PhrasesContext';

const TermsAndContitionsPage = () => {
	const classes = useStyles();
	const [terms, setTerms] = useState();
	const [isLoading, setIsLoading] = useState(false);
	const [phrases] = useContext(PhrasesContext);

	const locale = useIntl().locale;
	useEffect(() => {
		setIsLoading(true);
		getTermsAndConditions().then((Res) => {
			locale == 'en'
				? setTerms(Res.data.TermsAndConditions.en)
				: setTerms(Res.data.TermsAndConditions.ar);
			setIsLoading(false);
		});
	}, []);

	return (
		<div className={classes.container}>
			{isLoading ? (
				<div style={{ textAlign: 'center', margin: '15% 0px' }}>
					{' '}
					<CircularProgress
						style={{ alignSelf: 'center' }}
						size="4rem"
						variant="indeterminate"
					/>
				</div>
			) : (
				<div>
					<Container>
						<h2 className={classes.termHeader}>
							{phrases?.lblTermsConditions?.[locale]}
						</h2>
						<Typography
							align="left"
							variant="subtitle1"
							className={classes.content}
							style={{
								fontSize: '14px',
								color: '#2d2d2d',
								padding: '10px 40px !important',
							}}
							dangerouslySetInnerHTML={{ __html: terms }}
						></Typography>
					</Container>
				</div>
			)}
		</div>
	);
};

export default TermsAndContitionsPage;
