import React from 'react';

import useStyles from './styles';
import { Grid } from '@material-ui/core';
import CallIcon from '../../../assets/icons/contact-us/call.png';
import FaxIcon from '../../../assets/icons/contact-us/fax.png';
import PinIcon from '../../../assets/icons/contact-us/location.png';
import EmailIcon from '../../../assets/icons/contact-us/email.png';
const HeaderCards = ({ data }) => {
	const classes = useStyles();

	return (
		<div>
			<div className={classes.cardContainer}>
				<Grid container>
					<Grid
						xs={12}
						sm={6}
						lg={3}
						style={{ padding: '0px 20px 0px 0px' }}
						className={classes.cardGrid}
					>
						<div className={classes.card}>
							<img className={classes.img} src={CallIcon} alt="call" />
							<div className={classes.descSection}>
								<h6 className={classes.cardName}>Call</h6>
								{data.telephone &&
									data.telephone.map((v, i) => {
										return (
											<p key={i} className={classes.cardDesc}>
												{v}
											</p>
										);
									})}
							</div>
						</div>
					</Grid>

					<Grid
						xs={12}
						sm={6}
						lg={3}
						style={{ padding: '0px 20px 0px 0px' }}
						className={classes.cardGrid}
					>
						<div className={classes.card}>
							<img className={classes.img} src={FaxIcon} alt="fax" />
							<div className={classes.descSection}>
								<h6 className={classes.cardName}>Fax</h6>
								<p className={classes.cardDesc}>{data?.fax}</p>
							</div>
						</div>
					</Grid>

					<Grid
						xs={12}
						sm={6}
						lg={3}
						style={{ padding: '0px 20px 0px 0px' }}
						className={classes.cardGrid}
					>
						<div className={classes.card}>
							<img className={classes.img} src={EmailIcon} alt="call" />
							<div className={classes.descSection}>
								<h6 className={classes.cardName}>Email</h6>
								<p className={classes.cardDesc}>{data?.email}</p>
							</div>
						</div>
					</Grid>

					<Grid
						xs={12}
						sm={6}
						lg={3}
						style={{ padding: '0px 20px 0px 0px' }}
						className={classes.cardGrid}
					>
						<div className={classes.card}>
							<img className={classes.img} src={PinIcon} alt="call" />
							<div className={classes.descSection}>
								<h6 className={classes.cardName}>Visit</h6>
								<p className={classes.cardDesc}>
									Check out our branches below to visit
								</p>
							</div>
						</div>
					</Grid>
				</Grid>
			</div>
		</div>
	);
};

export default HeaderCards;
