import * as moment from 'moment';

export const getImageUrl = (url, w, h) => {
	let newUrl = url?.replace('t_,w_[w],h_[h]', `t_,w_${w},h_${h}`);

	return newUrl;
};

export const getImageUrlSliderFull = (url) => {
	const index = url?.indexOf('t_,w_[w],h_[h]');
	if (index) {
		return url.slice(0, index, url.length - index);
	}

	return url;
};

export const normalize = (arr = [], key = 'id') => {
	return arr.reduce((acc, currentValue) => {
		acc[currentValue[key]] = currentValue;
		return acc;
	}, {});
};

export const getTimeDiffAsStamp = (epoch = 0) => {
	const current = moment();
	return current > epoch
		? moment.duration(current.diff(moment.unix(epoch)))
		: moment.duration(moment.unix(epoch).diff(current));
};

export const getTimeDiff = (epoch = 0) => {
	const duration = getTimeDiffAsStamp(epoch);
	return {
		days: duration.as('days'),
		hours: duration.as('hours'),
		minutes: duration.as('minutes'),
		seconds: duration.as('seconds'),
	};
};

export const paginateArr = (pageSize = 1, arr) => {
	const paginatedArr = {};
	let j = 0;
	for (let i = 0; i < arr.length; i += pageSize) {
		j++;
		paginatedArr[j] = arr.slice(i, i + pageSize);
	}
	return paginatedArr;
};

export const RENDER_TYPE = {
	CHECKBOX: 'CHECKBOX',
	DROPDOWN: 'DROPDOWN',
	RADIO: 'RADIO',
	RANGE: 'RANGE',
	RADIO_BUTTONS_WITH_ALL: 'RADIO_BUTTONS_WITH_ALL',
};
