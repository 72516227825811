import React, { useContext, useState, useEffect } from 'react';
import AppBar from '@material-ui/core/AppBar';
import {
	Button,
	Container,
	Drawer,
	IconButton,
	InputBase,
	Paper,
	Popper,
	Toolbar,
	Typography,
	useMediaQuery,
} from '@material-ui/core';
import { Call, Menu, Search } from '@material-ui/icons';
import LocationOn from '../../../assets/icons/home/icons-orange-map.svg';
import Logo from '../../../assets/images/logo.png';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { useStyles } from './styles';
import { renderMenuList, renderSideMenu } from './helpers';
import { LocalizationContext } from '../../../context/LocalizationContext';
import { AuthContext } from '../../../context/AuthContext';
import Badge from '@material-ui/core/Badge';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import ShoppingCart from '../../ShoppingCart/ShoppingCart';
import TimeDown from '../../TimeDown';
import { ShoppingCartContext } from '../../../context/ShoppingCartContext';
import ProfileHeader from './profile';
import HeartIcon from '@material-ui/icons/Favorite';
import { getOrderCount } from '../../../utils/checkout';
import BidDropDown from './bids';
import { getAllWatchList } from '../../../api/myWatchList';
import Cookies from 'js-cookie';
import { MyWatchlistContext } from '../../../context/WatchlistContext';
import { transform } from 'lodash';
import { PhrasesContext } from '../../../context/PhrasesContext';
import { FormattedMessage, useIntl } from 'react-intl';
import { getAuctionCategories } from '../../../api/home';
import { auctionCategoryHandler } from '../../../utils/autionType';

function Header() {
	const { pathname } = useLocation();
	const isSmallScreen = useMediaQuery('(max-width:1010px)');
	const isExtraSmallScreen = useMediaQuery('(max-width:600px)');
	const classes = useStyles(isSmallScreen, isExtraSmallScreen)();
	const [showSideMenu, setSideMenuVisibility] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const [locale, switchLocale] = useContext(LocalizationContext);
	const [auth] = useContext(AuthContext);
	const [isCartOpened, setCartOpened] = useState(null);
	const [shoppingCartData, , auctionCheckouts] = useContext(
		ShoppingCartContext
	);
	const [watchlistCount] = useContext(MyWatchlistContext);

	const history = useHistory();
	const pathName = useLocation().pathname;
	const [phrases] = useContext(PhrasesContext);
	const [auctionCategories, setAuctionCategories] = useState([]);
	const [others, setOthers] = useState([]);
	const [plates, setPlates] = useState([]);

	useEffect(() => {
		getAuctionCategories().then((res) => {
			if (res?.data?.Auctions?.length > 0) {
				const data = auctionCategoryHandler(res.data.Auctions, true);
				setAuctionCategories(data.data);
				setOthers(data.others);
				setPlates(data.plates);
			}
		});
	}, []);

	const registerPages = () => {
		if (
			pathName == '/signup' ||
			pathName == '/otp' ||
			pathName == '/otp-forget-password' ||
			pathName == '/resetPassword'
		) {
			return true;
		} else {
			return false;
		}
	};

	const handleClick = (event) => {
		setAnchorEl(anchorEl ? null : event.currentTarget);
	};
	useEffect(() => {
		setAnchorEl(null);
	}, [pathName]);

	useEffect(() => {
		switchLocale(localStorage.getItem('lang') || 'en');
	}, []);

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popper' : undefined;

	const renderExtraNavs = () => {
		return (
			<>
				<BidDropDown></BidDropDown>

				<IconButton
					onClick={() => history.push(`/auctions/my-watchlist`)}
					className={classes.extraNavs}
				>
					<div className={classes.shoppingCart}>
						<Badge
							className={classes.customBadge}
							badgeContent={watchlistCount}
							showZero={false}
							color="primary"
						>
							<HeartIcon
								style={{ color: '#b6bfc6', fontSize: isSmallScreen && '24px' }}
							/>
						</Badge>
					</div>
				</IconButton>
			</>
		);
	};

	const renderActiveColor = () => {
		return (
			isCartOpened ||
			pathName.includes('/auctions/order-summary') ||
			pathName.includes('/auctions/sop') ||
			pathName.includes('/auctions/checkout')
		);
	};

	const renderShoppingCart = () => {
		return (
			<IconButton
				onClick={(event) => {
					setCartOpened(event.currentTarget);
				}}
				className={
					renderActiveColor() ? classes.shoppingCartButton : classes.extraNavs
				}
				style={
					{
						// backgroundColor: shoppingCartData?.count ? '#ff422f' : '#fff',
					}
				}
			>
				<div className={classes.shoppingCart}>
					<Badge
						className={classes.customBadge}
						badgeContent={
							shoppingCartData?.count + getOrderCount(auctionCheckouts) || null
						}
						showZero
						color="primary"
					>
						<ShoppingCartIcon
							style={{
								color: renderActiveColor() ? '#FFF' : '#b6bfc6',
								fontSize: isSmallScreen && '20px',
							}}
						/>
					</Badge>
					{shoppingCartData?.count > 0 && (
						<Typography
							className={classes.timeLeft}
							style={{ color: renderActiveColor() ? '#fff' : '#ff422f' }}
							variant="h6"
						>
							<TimeDown
								showLetters={true}
								timeStyle={classes.timeLeft}
								upperCase={true}
								seconds={
									!!shoppingCartData?.MyCartExpiry &&
									shoppingCartData?.MyCartExpiry * 1000
								}
								sperator=":"
							/>
						</Typography>
					)}
				</div>
			</IconButton>
		);
	};

	return (
		<>
			{
				<ShoppingCart
					cartItems={shoppingCartData}
					setCartOpened={setCartOpened}
					isCartOpened={isCartOpened}
				/>
			}
			<Container component="header" maxWidth={false}>
				<div className={classes.header}>
					<div className={classes.topBar}>
						<div
							role="button"
							tabIndex="0"
							onClick={() => {
								localStorage.setItem('lang', 'en');
								switchLocale('en');
							}}
							className={`${locale === 'en' ? classes.activeLocale : ''} ${
								classes.locale
							}`}
						>
							<span className={classes.spanLang}>
								{locale == 'en' && '//'} English {locale == 'ar' && '//'}
							</span>
						</div>
						<div
							role="button"
							tabIndex="0"
							onClick={() => {
								localStorage.setItem('lang', 'ar');
								switchLocale('ar');
							}}
							className={`${locale === 'ar' ? classes.activeLocale : ''} ${
								classes.locale
							}`}
						>
							<span className={classes.spanLang}>
								{locale == 'en' && '//'} Arabic {locale == 'ar' && '//'}
							</span>
						</div>
						<div className={classes.topBarExtra}>
							<Button
								startIcon={
									<Call
										style={{
											color: '#ff422f',
											marginLeft:
												localStorage.getItem('lang') == 'ar' ? '5px' : '0px',
											transform:
												localStorage.getItem('lang') == 'ar'
													? 'rotate(245deg)'
													: 'rotate(360deg)',
										}}
									/>
								}
								style={{ margin: '0px 2.7vw' }}
							>
								<span className={classes.spanCall}>
									{phrases['btnCallUsAnytime']?.[locale]}
								</span>
								&nbsp;
								{isExtraSmallScreen ? null : (
									<a href="tel:971600545454">(+971) 600 545454</a>
								)}
							</Button>
							<Button
								onClick={() => history.push('/contact')}
								startIcon={
									<LocationOn
										style={{
											color: '#ff422f',
											marginLeft:
												localStorage.getItem('lang') == 'ar' ? '8px' : '0px',
										}}
									/>
								}
							>
								{isExtraSmallScreen ? (
									<span className={classes.spanCall}>
										<FormattedMessage id="header.contact.smallLocation" />
									</span>
								) : (
									<span className={classes.spanCall}>
										{phrases['lblVisitOurOffice']?.[locale]}
									</span>
								)}
								&nbsp;
							</Button>
						</div>
					</div>
					<AppBar position="static" style={{ boxShadow: 'none' }}>
						<Toolbar disableGutters={true}>
							<div className={classes.logoContainer}>
								<Link to="/" style={{ textDecoration: 'none' }}>
									<img src={Logo} alt="logo" width="27px" />
								</Link>
							</div>
							{isSmallScreen ? (
								<>
									<IconButton
										edge="start"
										className={classes.menuButton}
										color="inherit"
										aria-label="menu"
										onClick={() => {
											setSideMenuVisibility(true);
										}}
									>
										<Menu />
									</IconButton>
									<Drawer
										BackdropProps={{
											style: { background: 'transparent' },
										}}
										open={showSideMenu}
										onClose={() => {
											setSideMenuVisibility(false);
										}}
										className={classes.drawer}
									>
										{renderSideMenu({
											setSideMenuVisibility,
											pathname,
											classes,
											auctionCategories,
											others,
											plates,
											locale,
											phrases,
											auth,
										})}
									</Drawer>
								</>
							) : (
								renderMenuList({ pathname, classes })
							)}
							<div className={classes.extraHeader}>
								{auth?.isAuth && !isExtraSmallScreen && renderShoppingCart()}
								{auth?.isAuth && !isExtraSmallScreen && renderExtraNavs()}

								{auth?.isAuth ? (
									<ProfileHeader
										data={auth?.userProfile}
										phrases={phrases}
									></ProfileHeader>
								) : (
									<>
										<Link
											to="/signup"
											style={{
												borderBottom: registerPages()
													? '3px solid #ff422f'
													: !isSmallScreen && '1px solid #e2e2e2',
												borderLeft: !isSmallScreen && '1px solid #e2e2e2',
											}}
										>
											<Button
												style={{
													color: registerPages() && '#ff422f',
												}}
												variant="contained"
												className="joinButton"
											>
												<FormattedMessage id="header.button.joinUs" />
											</Button>
										</Link>
										<Link to="/login">
											<Button variant="contained" className="loginButton">
												<FormattedMessage id="header.button.login" />
											</Button>
										</Link>
									</>
								)}
								{!isExtraSmallScreen && (
									<Button
										variant="contained"
										color="primary"
										className="searchIconContained"
										aria-describedby={id}
										onClick={handleClick}
										style={
											auth?.isAuth
												? { background: '#2d2d2d' }
												: { background: '#ff422f' }
										}
									>
										<Search style={{ fontSize: 35 }} />
									</Button>
								)}
							</div>
						</Toolbar>
					</AppBar>
					<Popper id={id} open={open} anchorEl={anchorEl}>
						<Paper component="form" className={classes.searchInput}>
							<InputBase
								className={classes.input}
								placeholder={phrases['lblSearch']?.[locale]}
								inputProps={{ 'aria-label': 'search' }}
							/>
							<IconButton
								type="submit"
								className={classes.iconButton}
								aria-label="search"
							>
								<Search />
							</IconButton>
						</Paper>
					</Popper>
				</div>
				<div className={classes.responsiveExtraNavs}>
					{auth?.isAuth && isExtraSmallScreen && renderShoppingCart()}
					{auth?.isAuth && isExtraSmallScreen && renderExtraNavs()}
				</div>
			</Container>
		</>
	);
}

export default Header;
