import axiosInstance from '../axios/axiosInstance';
import Cookies from 'js-cookie';
const baseURL = process.env.REACT_APP_API_BASE_URL;

export const getMyDepositInfo = (id) => {
	return axiosInstance.get(`account/deposits`);
};
export const sendToPayment = (type, amount) => {
	var host = window.location.href.split('/auctions/')[0];
	const token = Cookies.get('authToken');
	window.open(
		`https://payment.eas.ae/Home/PayTabsV2?type=dep&lang=e&source=androidphone&callback=${host}/auctions/payment-success&callbackfailure=${host}/auctions/payment-fail&aid=${type}&amount=${amount}&authtoken=${token}`,
		'_self'
	);
};

export const getBranches = () => axiosInstance.get(`common/contactus2`);

export const uploadReceipt = (image) =>
	axiosInstance.post(`account/uploadreceipt`, image);

export const getDepositHistory = () =>
	axiosInstance.get('account/deposithistory');

export const getOtherAucsDeposits = async (depositTypeString) => {
	const depositType = parseInt(depositTypeString, 10);
	const auctionTypeProperty = parseInt(
		process.env.REACT_APP_PROPERTY_AUCTION_TYPE,
		10
	);
	const res = await getDepositHistory();
	const filteredDeposits = res.data.Deposits.filter(
		(deposit) =>
			deposit.depositType === depositType &&
			deposit.auctionTypeId !== auctionTypeProperty
	);
	return filteredDeposits;
};

export const requestRefund = async (depositId, amount) => {
	try {
		const res = await axiosInstance.get(
			`account/requestrefund?depositid=${depositId}&amount=${amount}`
		);
		return await axiosInstance.get(
			`${baseURL}account/confirmrequestrefund?amount=${res.data.amount}&confirmid=${res.data.confirmid}`
		);
	} catch (error) {
		return error;
	}
};
