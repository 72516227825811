import { ACTION_TYPES } from '../actionTypes';
import produce from 'immer';
import * as _ from 'lodash';
import { RENDER_TYPE } from '../../utils/helper';

const initialState = {
	filterSelections: {
		listingTypes: {
			header: 'vehicles.filter.listing.title',
			value: 'vehicles',
			type: RENDER_TYPE.RADIO,
			vehicles: {
				label: 'vehicles.filter.listing.vehiclesRadioBtn',
				children: {
					condition: {
						header: 'vehicles.filter.condition.title',
						type: RENDER_TYPE.CHECKBOX,
						newCondition: {
							label: 'vehicles.filter.condition.newConditionCheckBoxBtn',
							value: false,
						},
						used: {
							label: 'vehicles.filter.condition.usedConditionCheckBoxBtn',
							value: false,
						},
						scrap: {
							label: 'vehicles.filter.condition.scrapConditionCheckBoxBtn',
							value: false,
						},
					},
					make: {
						header: 'vehicles.filter.make.title',
						type: RENDER_TYPE.DROPDOWN,
						value: [],
					},
					model: {
						header: 'vehicles.filter.model.title',
						type: RENDER_TYPE.DROPDOWN,
						value: [],
					},
					price: {
						header: 'vehicles.filter.price.title',
						type: RENDER_TYPE.RANGE,
						value: { from: '0', to: '100' },
					},
					year: {
						header: 'vehicles.filter.year.title',
						type: RENDER_TYPE.RANGE,
						value: { from: '0', to: '100' },
					},
					km: {
						header: 'vehicles.filter.km.title',
						type: RENDER_TYPE.RANGE,
						value: { from: '0', to: '100' },
					},
				},
			},
			machinery: {
				label: 'vehicles.filter.listing.machineryRadioBtn',
				children: {},
			},
		},
	},
	viewType: 'Grid',
};

const updateViewType = (state, { viewType }) => {
	return produce(state, (draftState) => {
		draftState.viewType = viewType;
	});
};
const clearFilters = (state) => {
	//TODO stupid tmp fix for now
	const makeOptions =
		state.filterSelections?.listingTypes?.vehicles?.children?.make?.options;
	const modelOptions =
		state.filterSelections?.listingTypes?.vehicles?.children?.model?.options;
	return {
		filterSelections: {
			listingTypes: {
				header: 'vehicles.filter.listing.title',
				value: 'vehicles',
				type: RENDER_TYPE.RADIO,
				vehicles: {
					label: 'vehicles.filter.listing.vehiclesRadioBtn',
					children: {
						condition: {
							header: 'vehicles.filter.condition.title',
							type: RENDER_TYPE.CHECKBOX,
							newCondition: {
								label: 'vehicles.filter.condition.newConditionCheckBoxBtn',
								value: false,
							},
							used: {
								label: 'vehicles.filter.condition.usedConditionCheckBoxBtn',
								value: false,
							},
							scrap: {
								label: 'vehicles.filter.condition.usedConditionCheckBoxBtn',
								value: false,
							},
						},
						make: {
							header: 'vehicles.filter.make.title',
							type: RENDER_TYPE.DROPDOWN,
							value: [],
							options: makeOptions,
						},
						model: {
							header: 'vehicles.filter.model.title',
							type: RENDER_TYPE.DROPDOWN,
							value: [],
							options: modelOptions,
						},
						price: {
							header: 'vehicles.filter.price.title',
							type: RENDER_TYPE.RANGE,
							value: { from: '0', to: '100' },
						},
						year: {
							header: 'vehicles.filter.year.title',
							type: RENDER_TYPE.RANGE,
							value: { from: '0', to: '100' },
						},
						km: {
							header: 'vehicles.filter.km.title',
							type: RENDER_TYPE.RANGE,
							value: { from: '0', to: '100' },
						},
					},
				},
				machinery: {
					label: 'vehicles.filter.listing.machineryRadioBtn',
					children: {},
				},
			},
		},
	};
};

const updateFilters = (state, { path, value }) => {
	return produce(state, (draftState) => {
		_.set(draftState, `filterSelections.${path}.value`, value);
	});
};

const updateFilterOptions = (state, { path, options }) => {
	return produce(state, (draftState) => {
		_.set(draftState, `filterSelections.${path}.options`, options);
	});
};
const reducer = (state = initialState, action) => {
	switch (action.type) {
		case ACTION_TYPES.UPDATE_VEHICLE_FILTERS:
			return updateFilters(state, action);
		case ACTION_TYPES.UPDATE_VEHICLE_OPTIONS:
			return updateFilterOptions(state, action);
		case ACTION_TYPES.CLEAR_VEHICLE_FILTERS:
			return clearFilters(state, action);
		case ACTION_TYPES.UPDATE_VIEW_TYPE:
			return updateViewType(state, action);
		default:
			return state;
	}
};

export default reducer;
