import React, { useContext, useEffect, useState } from 'react';
import { getShoppingCart } from '../api/home';
import { AuthContext } from './AuthContext';
import { getCheckouts } from '../api/checkout-auction';
import { getAllWatchList } from '../api/myWatchList';

const MyWatchlistContext = React.createContext([{}, () => {}]);

const WatchlistProvider = ({ children }) => {
	const [watchlist, setWatchlist] = useState({});
	const [watchlistCount, setWatchlistCount] = useState(0);

	const [auth] = useContext(AuthContext);

	const incrementDecrementWatchlistCount = (step) =>
		setWatchlistCount((prevState) => prevState + step);

	useEffect(() => {
		if (auth?.isAuth) {
			getAllWatchList().then((res) => {
				if (res?.data) {
					setWatchlist(res.data);
					if (res?.data?.count !== watchlistCount) {
						setWatchlistCount(res.data.watchingCount);
					}
				}
			});
		}
	}, [auth?.isAuth, watchlistCount]);

	return (
		<MyWatchlistContext.Provider
			value={[watchlistCount, incrementDecrementWatchlistCount]}
		>
			{children}
		</MyWatchlistContext.Provider>
	);
};

export { WatchlistProvider, MyWatchlistContext };
