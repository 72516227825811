import React, { useState, useEffect } from 'react';
import BidIcon from '../../../assets/icons/home/how-it-works/bid.svg';

import { useStyles } from './styles';
import {
	IconButton,
	Popover,
	Backdrop,
	useMediaQuery,
	SvgIcon,
	Badge,
} from '@material-ui/core';
import GavelIconActive from '../../../assets/icons/vehicle/shape1.svg';
import GavelIcon from '../../../assets/icons/vehicle/details/brief-information/bids.svg';

import { Link, useHistory, useLocation } from 'react-router-dom';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArchiveIcon from '@material-ui/icons/Archive';
import WonIcon from '../../../assets/images/header/won-ic.png';
import { getAllBids } from '../../../api/myBids';

const BidDropDown = () => {
	const isSmallScreen = useMediaQuery('(max-width:1010px)');
	const isExtraSmallScreen = useMediaQuery('(max-width:600px)');
	const classes = useStyles(isSmallScreen, isExtraSmallScreen)();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [myBid, setMyBid] = useState({});
	const pathName = useLocation().pathname;
	const open = Boolean(anchorEl);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};
	useEffect(() => {
		getAllBids().then((Res) => {
			setMyBid(Res?.data);
		});
	}, [anchorEl]);
	const renderList = () => {
		return (
			<div>
				<div className={classes.list}>
					<Link
						className={classes.itemBid}
						to={`/auctions/my-bids`}
						onClick={() => handleClose()}
					>
						<span>
							<GavelIcon className={classes.colorBidIcons} />
							<span className={classes.itemBidName}> My Bids</span>
							{myBid?.myBidsCount > 0 && (
								<Badge className={classes.badge}>{myBid?.myBidsCount}</Badge>
							)}
						</span>
						<ArrowRightIcon style={{ color: '#ff422f' }} color="primary" />
					</Link>

					<Link
						className={classes.itemBid}
						to={`/auctions/won-items`}
						onClick={() => handleClose()}
					>
						<span>
							<img src={WonIcon} className={classes.colorBidIcons} />
							<span className={classes.itemBidName}> Won Items</span>
							{myBid?.myWonCount > 0 && (
								<Badge className={classes.badge}>{myBid?.myWonCount}</Badge>
							)}
						</span>
						<ArrowRightIcon style={{ color: '#ff422f' }} color="primary" />
					</Link>
				</div>
			</div>
		);
	};

	const renderActiveColor = () => {
		return (
			open ||
			pathName === '/auctions/won-items' ||
			pathName === '/auctions/my-bids'
		);
	};

	return (
		<>
			<IconButton
				className={
					renderActiveColor() ? classes.shoppingCartButton : classes.extraNavs
				}
				onClick={handleClick}
				aria-describedby={'bid-pop'}
			>
				<div className={classes.shoppingCart} style={{ position: 'relative' }}>
					{!renderActiveColor() ? (
						<GavelIcon
							style={{
								fontSize: isSmallScreen && '20px',
								width: isSmallScreen ? '20px' : '27px',
								height: isSmallScreen ? '20px' : '27px',
							}}
						/>
					) : (
						<GavelIconActive
							style={{
								fontSize: isSmallScreen && '20px',
								width: isSmallScreen ? '20px' : '27px',
								height: isSmallScreen ? '20px' : '27px',
							}}
						/>
					)}
					<span style={{ position: 'absolute', top: '-10px', right: '-15px' }}>
						{(myBid?.myWonCount || 0) + (myBid?.myBidsCount || 0) > 0 && (
							<Badge
								style={{
									padding: '2px 5px',
									fontWeight: '500',
									border: renderActiveColor() && '1px solid',
								}}
								className={classes.badge}
								showZero={false}
							>
								{(myBid?.myWonCount || 0) + (myBid?.myBidsCount || 0)}
							</Badge>
						)}
					</span>
				</div>
			</IconButton>

			<Popover
				id={'bid-pop'}
				BackdropComponent={Backdrop}
				BackdropProps={{ timeout: 500 }}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				className={classes.popoverBid}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left   ',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
			>
				{renderList()}
			</Popover>
		</>
	);
};

export default BidDropDown;
